import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  items: [],
  itemsFilter: "ALL",
  status: "idle",
  totalMessagesCount: undefined,
}

export const totalMessagesCount = items => {
  if (!items?.length) return
  const count =
    items?.reduce(
      (sum, val) => (sum = val.status === "NEW" ? sum + 1 : sum),
      0
    ) || 0
  const newMsgs = items?.reduce((sum, item) => {
    sum = sum + (item.chatMessages > 0 ? 1 : 0)
    return sum
  }, 0)
  return count + newMsgs
}

// const sortBookings = items => {
//   const messagesSort = (a, b) => {
//     if (a.chatNewMessages > b.chatNewMessages) {
//       return -1
//     } else {
//       return 1
//     }
//   }
//   const sorted = [...items]
//     .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
//     .sort(messagesSort)
//   debugger
//   return sorted
// }

const sortBookings = bookings => {
  const hasNewMessages = item => item?.chatNewMessages > 0
  const hasStatus = status => item => item.status === status

  const newMessageBookings = bookings
    .filter(hasNewMessages)
    .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))

  const payBookings = bookings
    .filter(item => !hasNewMessages(item) && hasStatus("PAY")(item))
    .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))

  const newBookings = bookings
    .filter(item => !hasNewMessages(item) && hasStatus("NEW")(item))
    .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))

  const declinedBookings = bookings
    .filter(item => !hasNewMessages(item) && hasStatus("DECLINED")(item))
    .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))

  const otherBookings = bookings
    .filter(
      item =>
        !hasNewMessages(item) &&
        !["PAY", "NEW", "DECLINED"].includes(item.status)
    )
    .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))

  return [
    ...newMessageBookings,
    ...payBookings,
    ...newBookings,
    ...otherBookings,
    ...declinedBookings,
  ]
}

const InboxSlice = createSlice({
  name: "Inbox",
  initialState,
  reducers: {
    InboxLoading(state, action) {
      state.status = "loading"
    },
    InboxAddItems(state, action) {
      state.items = sortBookings(action.payload)
      state.totalMessagesCount = totalMessagesCount(action.payload)
      state.status = "idle"
    },
    InboxSetItemFilter(state, action) {
      state.itemsFilter = action.payload.itemsFilter
    },
  },
  extraReducers: {},
})

export const {
  InboxAddItems,
  InboxSetItemFilter,
  InboxLoading,
} = InboxSlice.actions

export default InboxSlice.reducer

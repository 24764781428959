import * as Sentry from "@sentry/browser"
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { SearchLocationInputSet } from "../slices/searchSlice"

const initialState = {
  coords: {
    lat: undefined,
    lon: undefined,
  },
  updatedOn: undefined,
}

export const GOOGLE_API_KEY = "AIzaSyBXEKbCDGxfHZo4A5N52sk7EueMuj3kPUI"

export const LocationServiceFetch = createAsyncThunk(
  "location/LocationServiceFetch",
  async ({}, { rejectWithValue, dispatch }) => {
    try {
      let res = await fetch(
        `https://www.googleapis.com/geolocation/v1/geolocate?key=${GOOGLE_API_KEY}`,
        { method: "POST" }
      )
      const data = await res.json()
      if (data?.location?.lat)
        dispatch(
          SearchLocationInputSet({
            source: "GOOGLE_API",
            lng: data.location.lng,
            lat: data.location.lat,
          })
        )
      return data
    } catch (err) {
      console.error("location/LocationServiceFetch", err)
      return rejectWithValue(err)
    }
  }
)

const locationSlice = createSlice({
  name: "location",
  initialState,
  reducers: {
    resetStatus(state) {
      state.status = "idle"
    },
    LocationUpdate(state, action) {
      const { longitude, latitude } = action.payload.coords
      state.coords = { lat: latitude, lon: longitude }
      state.error = false
    },
    LocationUpdateError(state, action) {
      state.error = { ...action.payload }
    },
  },
  extraReducers: {
    [LocationServiceFetch.fulfilled]: (state, action) => {
      if (action?.payload?.location?.lat) {
        state.coords.lat = action.payload.location.lat
        state.coords.lon = action.payload.location.lng
        state.updatedOn = new Date().toISOString()
      } else {
        Sentry.captureException(action.payload.location)
      }
    },
    [LocationServiceFetch.rejected]: (state, action) => {
      // state.status = 'rejected';
    },
    [LocationServiceFetch.pending]: (state, action) => {
      // state.status = 'loading';
    },
  },
})

export const { LocationUpdate, LocationUpdateError } = locationSlice.actions

export default locationSlice.reducer

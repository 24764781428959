/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const nearbyListings = /* GraphQL */ `
  query NearbyListings(
    $location: LocationInput!
    $km: Int
    $limit: Int
    $nextToken: String
  ) {
    nearbyListingsKm(
      location: $location
      km: $km
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        sort
        timezone
        gen_title
        gen_desc
        gen_captainOnboard
        gen_type
        status
        spec_makeModel
        spec_length
        spec_year
        spec_capacity
        spec_numberEngines
        spec_horsepower
        spec_engineBrand
        spec_engineModel
        feature_BBQ
        feature_bimini
        feature_cabin
        feature_cuddyCabin
        feature_dinghy
        feature_dualForwardSeating
        feature_fishingGear
        feature_fuelTypeDiesel
        feature_galleyStoveOven
        feature_inverter
        feature_licensingRequired
        feature_singleEngine
        feature_toilet
        feature_watersportsEquipment
        feature_wiFi
        price_currency
        price_autobook
        price_1duration
        price_1
        price_1premium
        price_2duration
        price_2
        price_2premium
        price_3duration
        price_3
        price_3premium
        price_4duration
        price_4
        price_4premium
        price_5duration
        price_5
        price_5premium
        cancelDays
        cancelRefund
        detailsRules
        detailsToBring
        detailsDeparture
        location_address
        location {
          lat
          lon
        }
        location_vicinity
        createdAt
        updatedAt
        photos(limit: 100, filter: { enabled: { eq: true } }) {
          items {
            id
            listingCover
            listingId
            userId
            fileType
            photoType
            createdAt
            updatedAt
            enabled
          }
          nextToken
        }
        #        bookings {
        #          items {
        #            id
        #            owner
        #            listingId
        #            guestUser {
        #              firstName
        #              lastName
        #            }
        #            hostUser {
        #              firstName
        #              lastName
        #            }
        #            hostId
        #            priceOption
        #            price
        #            duration
        #            currency
        #            adults
        #            children
        #            message
        #            departurePrimary
        #            departurePrimaryPremium
        #            departureSecondary
        #            departureSecondaryPremium
        #            departureHost
        #            acceptedAtHost
        #            acceptedAtGuest
        #            paymentSuccess
        #            verifySubmit
        #            secretCodeSubmit
        #            secretCodeSubmitAt
        #            cancelAtHost
        #            hostPayoutId
        #            seenGuest
        #            seenHost
        #            createdAt
        #            updatedAt
        #          }
        #          nextToken
        #        }
        #        user {
        #          id
        #          firstName
        #          lastName
        #          currency
        #          about
        #          newsletter
        #          createdAt
        #          updatedAt
        #          photoPrimary
        #          photos(limit: 100, filter: { enabled: { eq: true } }) {
        #            items {
        #              id
        #              listingId
        #              fileType
        #              enabled
        #              photoType
        #            }
        #          }
        #        }

        user {
          id
          firstName
          currency
          about
          photoPrimary
        }
        reviews {
          items {
            id
            rating
          }
          nextToken
        }
      }
      total
      nextToken
    }
  }
`

export const getListing = /* GraphQL */ `
  query GetListing($id: ID!) {
    getListing(id: $id) {
      id
      userId
      sort
      timezone
      gen_title
      gen_desc
      gen_captainOnboard
      gen_type
      status
      spec_makeModel
      spec_length
      spec_year
      spec_capacity
      spec_numberEngines
      spec_horsepower
      spec_engineBrand
      spec_engineModel
      feature_BBQ
      feature_bimini
      feature_cabin
      feature_cuddyCabin
      feature_dinghy
      feature_dualForwardSeating
      feature_fishingGear
      feature_fuelTypeDiesel
      feature_galleyStoveOven
      feature_inverter
      feature_licensingRequired
      feature_singleEngine
      feature_toilet
      feature_watersportsEquipment
      feature_wiFi
      price_currency
      price_autobook
      price_1duration
      price_1
      price_1premium
      price_2duration
      price_2
      price_2premium
      price_3duration
      price_3
      price_3premium
      price_4duration
      price_4
      price_4premium
      price_5duration
      price_5
      price_5premium
      cancelDays
      cancelRefund
      detailsRules
      detailsToBring
      detailsDeparture
      location_address
      location {
        lat
        lon
      }
      location_vicinity
      createdAt
      updatedAt
      photos(limit: 100, filter: { enabled: { eq: true } }) {
        items {
          id
          listingCover
          listingId
          userId
          fileType
          photoType
          createdAt
          updatedAt
          enabled
        }
        nextToken
      }
      #        bookings {
      #          items {
      #            id
      #            owner
      #            listingId
      #            guestUser {
      #              firstName
      #              lastName
      #            }
      #            hostUser {
      #              firstName
      #              lastName
      #            }
      #            hostId
      #            priceOption
      #            price
      #            duration
      #            currency
      #            adults
      #            children
      #            message
      #            departurePrimary
      #            departurePrimaryPremium
      #            departureSecondary
      #            departureSecondaryPremium
      #            departureHost
      #            acceptedAtHost
      #            acceptedAtGuest
      #            paymentSuccess
      #            verifySubmit
      #            secretCodeSubmit
      #            secretCodeSubmitAt
      #            cancelAtHost
      #            hostPayoutId
      #            seenGuest
      #            seenHost
      #            createdAt
      #            updatedAt
      #          }
      #          nextToken
      #        }
      #        user {
      #          id
      #          firstName
      #          lastName
      #          currency
      #          about
      #          newsletter
      #          createdAt
      #          updatedAt
      #          photoPrimary
      #          photos(limit: 100, filter: { enabled: { eq: true } }) {
      #            items {
      #              id
      #              listingId
      #              fileType
      #              enabled
      #              photoType
      #            }
      #          }
      #        }

      user {
        id
        firstName
        currency
        about
        photoPrimary
      }
      reviews {
        items {
          id
          rating
        }
        nextToken
      }
    }
  }
`
export const listListings = /* GraphQL */ `
  query ListListings(
    $filter: ModelListingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listListings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        timezone
        user {
          id
          firstName
          lastName
          currency
          about
          newsletter
          createdAt
          updatedAt
          photoPrimary
          stripeConnectPK
          photos {
            items {
              id
              listingId
              fileType
              photoType
              enabled
            }
          }
        }
        gen_title
        gen_desc
        gen_captainOnboard
        gen_type
        status
        photos {
          items {
            id
            listingCover
            listingId
            userId
            fileType
            photoType
            createdAt
            updatedAt
            enabled
          }
          nextToken
        }
        reviews {
          items {
            id
            listingId
            bookingId
            userId
            rating
            desc
            createdAt
            updatedAt
            user {
              id
              firstName
              lastName
            }
          }
          nextToken
        }
        spec_makeModel
        spec_length
        spec_year
        spec_capacity
        spec_numberEngines
        spec_horsepower
        spec_engineBrand
        spec_engineModel
        feature_BBQ
        feature_bimini
        feature_cabin
        feature_cuddyCabin
        feature_dinghy
        feature_dualForwardSeating
        feature_fishingGear
        feature_fuelTypeDiesel
        feature_galleyStoveOven
        feature_inverter
        feature_licensingRequired
        feature_singleEngine
        feature_toilet
        feature_watersportsEquipment
        feature_wiFi
        price_currency
        price_autobook
        price_1duration
        price_1
        price_1premium
        price_2duration
        price_2
        price_2premium
        price_3duration
        price_3
        price_3premium
        price_4duration
        price_4
        price_4premium
        price_5duration
        price_5
        price_5premium
        cancelDays
        cancelRefund
        detailsRules
        detailsToBring
        detailsDeparture
        location_address

        location_vicinity
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`

export const getPhoto = /* GraphQL */ `
  query GetPhoto($id: ID!) {
    getPhoto(id: $id) {
      id
      listingCover
      listingId
      userId
      type
      createdAt
      enabled
      updatedAt
    }
  }
`
export const listPhotos = /* GraphQL */ `
  query ListPhotos(
    $filter: ModelPhotoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPhotos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        listingCover
        listingId
        userId
        type
        createdAt
        enabled
        updatedAt
      }
      nextToken
    }
  }
`
export const getBooking = /* GraphQL */ `
  query GetBooking($id: ID!) {
    getBooking(id: $id) {
      id
      owner
      listingId
      timezone
      guestUser {
        id
        firstName
        lastName
        photoPrimary
        photos(filter: { photoType: { eq: PROFILE } }) {
          items {
            id
            photoType
            createdAt
          }
        }
      }
      hostUser {
        id
        firstName
        lastName
        photoPrimary
        stripeConnectPK
        photos(filter: { photoType: { eq: PROFILE } }) {
          items {
            id
            photoType
            createdAt
          }
        }
      }
      listing {
        id
        gen_title
        status
        detailsDeparture
        detailsRules
        sort
        timezone
        detailsToBring
        cancelDays
        cancelRefund
        reviews(limit: 1000) {
          items {
            id
            listingId
            bookingId
            userId
            rating
            desc
            createdAt
            updatedAt
            user {
              id
              firstName
              lastName
            }
          }
        }
        photos(limit: 1000) {
          items {
            id
            listingCover
            enabled
          }
          nextToken
        }
        location_address
        location_vicinity
        location {
          lat
          lon
        }
      }
      hostId
      priceOption
      price
      pricePremium
      priceOptionPremium
      duration
      durationPremium
      currency
      fees
      adults
      children
      message
      departurePrimary
      departurePrimaryPremium
      departureSecondary
      departureSecondaryPremium
      departureHost
      acceptedAtHost
      acceptedAtGuest
      paymentSuccess
      verifySubmit
      secretCode
      secretCodeSubmit
      secretCodeSubmitAt
      secretCodeSuccess
      cancelAtHost
      declineAtGuest
      declineAtHost
      hostPayoutId
      chat {
        items {
          id
          createdAt
          from
          to
          toSeen
          message
        }
      }
      seenHost
      seenGuest
      createdAt
      updatedAt
    }
  }
`
export const listBookings = /* GraphQL */ `
  query ListBookings(
    $filter: ModelBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBookings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        listingId
        priceOption
        price
        duration
        currency
        adults
        children
        message

        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getUserStripe = /* GraphQL */ `
  query GetUserData($id: ID!) {
    getUserData(id: $id) {
      id
      stripeConnectPK
    }
  }
`

export const getUser = /* GraphQL */ `
  query GetUserData($id: ID!) {
    getUserData(id: $id) {
      id
      firstName
      lastName
      currency
      about
      languages
      timezone
      termsAccept
      email
      tracking
      photos(
        limit: 100
        filter: { enabled: { eq: true }, photoType: { eq: PROFILE } }
      ) {
        items {
          id
          listingCover
          listingId
          userId
          fileType
          photoType
          size
          height
          width
          enabled
          createdAt
          updatedAt
        }
        nextToken
      }
      photoPrimary
      primaryType
      guestBookings {
        items {
          id
          owner
          listingId
          timezone
          guestUser {
            id
            firstName
            lastName
          }
          hostUser {
            id
            firstName
            lastName
            stripeConnectPK
          }
          listing {
            id
            timezone
            gen_title
            detailsDeparture
            detailsRules
            detailsToBring
            cancelDays
            cancelRefund
            location_address
            location_vicinity
            location {
              lat
              lon
            }
            spec_capacity
            reviews {
              items {
                id
                listingId
                bookingId
                userId
                rating
                desc
                createdAt
                updatedAt
                user {
                  id
                  firstName
                  lastName
                }
              }
            }
            photos {
              items {
                id
                listingCover
                enabled
              }
              nextToken
            }
          }
          hostId
          priceOption
          price
          pricePremium
          priceOptionPremium
          duration
          durationPremium
          currency
          fees
          adults
          children
          message
          departurePrimary
          departurePrimaryPremium
          departureSecondary
          departureSecondaryPremium
          departureHost
          acceptedAtHost
          acceptedAtGuest
          paymentSuccess
          verifySubmit
          secretCode
          secretCodeSubmit
          secretCodeSubmitAt
          secretCodeSuccess
          cancelAtHost

          declineAtGuest
          declineAtHost
          hostPayoutId
          chat {
            items {
              id
              createdAt
              from
              to
              toSeen
              message
            }
          }
          seenHost
          seenGuest
          createdAt
          updatedAt
        }
        nextToken
      }
      listings {
        items {
          id
          timezone
          userId
          gen_title
          gen_desc
          gen_captainOnboard
          gen_type
          status
          spec_makeModel
          spec_length
          spec_year
          spec_capacity
          spec_numberEngines
          spec_horsepower
          spec_engineBrand
          spec_engineModel
          feature_BBQ
          feature_bimini
          feature_cabin
          feature_cuddyCabin
          feature_dinghy
          feature_dualForwardSeating
          feature_fishingGear
          feature_fuelTypeDiesel
          feature_galleyStoveOven
          feature_inverter
          feature_licensingRequired
          feature_singleEngine
          feature_toilet
          feature_watersportsEquipment
          feature_wiFi
          price_currency
          price_autobook
          price_1duration
          price_1
          price_1premium
          price_2duration
          price_2
          price_2premium
          price_3duration
          price_3
          price_3premium
          price_4duration
          price_4
          price_4premium
          price_5duration
          price_5
          price_5premium
          cancelDays
          cancelRefund
          detailsRules
          detailsToBring
          detailsDeparture
          location_address
          location_vicinity
          createdAt
          updatedAt
          photos {
            items {
              id
              listingCover
              listingId
              userId
              fileType
              photoType
              size
              height
              width
              enabled
              createdAt
              updatedAt
            }
            nextToken
          }
          bookings(limit: 1000) {
            items {
              id
              owner
              listingId
              hostId
              guestUser {
                id
                firstName
                lastName
              }
              hostUser {
                id
                firstName
                lastName
                stripeConnectPK
              }
              listing {
                id
                gen_title
                detailsDeparture
                detailsRules
                detailsToBring
                cancelDays
                cancelRefund
                timezone
                photos {
                  items {
                    id
                    listingCover
                    enabled
                  }
                  nextToken
                }
              }
              priceOption
              price
              pricePremium
              priceOptionPremium
              fees
              duration
              durationPremium
              currency
              adults
              children
              message
              departurePrimary
              departurePrimaryPremium
              departureSecondary
              departureSecondaryPremium
              departureHost
              acceptedAtHost
              acceptedAtGuest
              paymentSuccess
              verifySubmit
              secretCodeSubmit
              secretCodeSubmitAt
              secretCodeSuccess
              cancelAtHost
              declineAtGuest
              declineAtHost
              hostPayoutId
              seenGuest
              seenHost
              chat {
                items {
                  id
                  createdAt
                  from
                  to
                  toSeen
                  message
                }
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          user {
            id
            firstName
            lastName
            stripeConnectPK
          }
        }
        nextToken
      }
      newsletter
      phone
      createdAt
      updatedAt
    }
  }
`
export const listUsers = /* GraphQL */ `
  query ListUserDatas(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserDatas(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        lastName
        currency
        about
        photos {
          nextToken
        }
        listings {
          nextToken
        }
        newsletter
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getReview = /* GraphQL */ `
  query GetReview($id: ID!) {
    getReview(id: $id) {
      id
      listingId
      bookingId
      userId
      rating
      desc
      createdAt
      updatedAt
    }
  }
`
export const listReviews = /* GraphQL */ `
  query ListReviews(
    $filter: ModelReviewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReviews(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        listingId
        bookingId
        userId
        rating
        desc
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`

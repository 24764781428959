export const SendEvents = ({ eventName, data }) => {
  if (typeof window === "undefined") {
    return
  }

  window.dataLayer?.push({
    event: eventName,
    data,
  })

  var callback = function () {
    if (typeof url != "undefined") {
      window.location = url
    }
  }

  if (typeof window !== "undefined" && typeof window?.gtag === "function") {
    if (eventName === "booking/BookingStepSubmit/1") {
      window.gtag("event", "conversion", {
        send_to: "AW-10888847158/gHzKCJ3Wi7kDELa-msgo",
        event_callback: callback,
        value: 10.0,
        currency: "CAD",
      })
    } else if (eventName === "booking/BookingStepSubmit/2") {
      window.gtag("event", "conversion", {
        send_to: "AW-10888847158/amcOCLLMkLkDELa-msgo",
        event_callback: callback,
        value: 10.0,
        currency: "CAD",
      })
    } else if (eventName === "booking/BookingStepSubmit/3") {
      window.gtag("event", "conversion", {
        send_to: "AW-10888847158/U53oCKHZkLkDELa-msgo",
        event_callback: callback,
        value: 10.0,
        currency: "CAD",
      })
    } else if (eventName === "booking/BookingStepSubmit/4") {
      window.gtag("event", "conversion", {
        send_to: "AW-10888847158/b2ZsCOXkkLkDELa-msgo",
        event_callback: callback,
        value: 10.0,
        currency: "CAD",
      })
    } else if (eventName === "booking/BookingStepSubmit/payment") {
      //TODO: payment
      // window.gtag("event", "conversion", {
      //   send_to: "AW-10888847158/gHzKCJ3Wi7kDELa-msgo",
      //   event_callback: callback,
      // })
    } else {
    }
    //send the event to GA
    window.gtag("event", eventName, { ...data })
  }
}
export const SendEventsFBEvent = ({ type, params }) => {
  if (typeof window !== "undefined" && typeof window?.fbq === "function") {
    window.fbq("track", type, params)
  }
}

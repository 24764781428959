/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createListing = /* GraphQL */ `
  mutation CreateListing(
    $input: CreateListingInput!
    $condition: ModelListingConditionInput
  ) {
    createListing(input: $input, condition: $condition) {
      id
      userId
      user {
        id
        firstName
        lastName
        currency
        about
        photos {
          nextToken
        }
        listings {
          nextToken
        }
        newsletter
        createdAt
        updatedAt
      }
      gen_title
      gen_desc
      gen_captainOnboard
      operator
      gen_type
      status
      photos {
        items {
          id
          listingCover
          listingId
          userId
          createdAt
          updatedAt
          enabled
        }
        nextToken
      }
      reviews {
        items {
          id
          listingId
          bookingId
          userId
          rating
          desc
          createdAt
          updatedAt
        }
        nextToken
      }
      spec_makeModel
      spec_length
      spec_year
      spec_capacity
      spec_numberEngines
      spec_horsepower
      spec_engineBrand
      spec_engineModel
      feature_BBQ
      feature_bimini
      feature_cabin
      feature_cuddyCabin
      feature_dinghy
      feature_dualForwardSeating
      feature_fishingGear
      feature_fuelTypeDiesel
      feature_galleyStoveOven
      feature_inverter
      feature_licensingRequired
      feature_singleEngine
      feature_toilet
      feature_watersportsEquipment
      feature_wiFi
      price_currency
      price_autobook
      price_1duration
      price_1
      price_1premium
      price_2duration
      price_2
      price_2premium
      price_3duration
      price_3
      price_3premium
      price_4duration
      price_4
      price_4premium
      price_5duration
      price_5
      price_5premium
      cancelDays
      cancelRefund
      detailsRules
      detailsToBring
      detailsDeparture
      location_address
      location {
        lat
        lon
      }
      location_vicinity
      createdAt
      updatedAt
    }
  }
`
export const updateListing = /* GraphQL */ `
  mutation UpdateListing(
    $input: UpdateListingInput!
    $condition: ModelListingConditionInput
  ) {
    updateListing(input: $input, condition: $condition) {
      id
      userId
      gen_title
      gen_desc
      gen_captainOnboard
      operator
      gen_type
      status
      spec_makeModel
      spec_length
      spec_year
      spec_capacity
      spec_numberEngines
      spec_horsepower
      spec_engineBrand
      spec_engineModel
      feature_BBQ
      feature_bimini
      feature_cabin
      feature_cuddyCabin
      feature_dinghy
      feature_dualForwardSeating
      feature_fishingGear
      feature_fuelTypeDiesel
      feature_galleyStoveOven
      feature_inverter
      feature_licensingRequired
      feature_singleEngine
      feature_toilet
      feature_watersportsEquipment
      feature_wiFi
      price_currency
      price_autobook
      price_1duration
      price_1
      price_1premium
      price_2duration
      price_2
      price_2premium
      price_3duration
      price_3
      price_3premium
      price_4duration
      price_4
      price_4premium
      price_5duration
      price_5
      price_5premium
      cancelDays
      cancelRefund
      detailsRules
      detailsToBring
      detailsDeparture
      location_address
      location {
        lat
        lon
      }
      location_vicinity
      createdAt
      updatedAt
    }
  }
`

export const createPhoto = /* GraphQL */ `
  mutation CreatePhoto(
    $input: CreatePhotoInput!
    $condition: ModelPhotoConditionInput
  ) {
    createPhoto(input: $input, condition: $condition) {
      id
      listingCover
      listingId
      userId
      type
      createdAt
      updatedAt
    }
  }
`
export const updatePhoto = /* GraphQL */ `
  mutation UpdatePhoto(
    $input: UpdatePhotoInput!
    $condition: ModelPhotoConditionInput
  ) {
    updatePhoto(input: $input, condition: $condition) {
      id
      listingCover
      listingId
      userId
      fileType
      photoType
      size
      height
      width
      enabled
      createdAt
      updatedAt
    }
  }
`
export const deletePhoto = /* GraphQL */ `
  mutation DeletePhoto(
    $input: DeletePhotoInput!
    $condition: ModelPhotoConditionInput
  ) {
    deletePhoto(input: $input, condition: $condition) {
      id
      listingCover
      listingId
      userId
      type
      createdAt
      updatedAt
    }
  }
`
export const createBooking = /* GraphQL */ `
  mutation CreateBooking(
    $input: CreateBookingInput!
    $condition: ModelBookingConditionInput
  ) {
    createBooking(input: $input, condition: $condition) {
      id
      owner
      listingId
      timezone
      notify
      guestUser {
        id
        firstName
        lastName
      }
      hostUser {
        id
        firstName
        lastName
      }
      listing {
        id
        gen_title
        detailsDeparture
        detailsRules
        sort
        timezone
        detailsToBring
        cancelDays
        cancelRefund
        location_address
        location {
          lat
          lon
        }
        location_vicinity
        reviews(limit: 1000) {
          items {
            id
            listingId
            bookingId
            userId
            rating
            desc
            createdAt
            updatedAt
            user {
              id
              firstName
              lastName
            }
          }
        }
        photos(limit: 1000) {
          items {
            id
            listingCover
            enabled
          }
          nextToken
        }
      }
      hostId
      priceOption
      price
      pricePremium
      priceOptionPremium
      duration
      durationPremium
      currency
      fees
      adults
      children
      message
      departurePrimary
      departurePrimaryPremium
      departureSecondary
      departureSecondaryPremium
      departureHost
      acceptedAtHost
      acceptedAtGuest
      paymentSuccess
      verifySubmit
      secretCode
      secretCodeSubmit
      secretCodeSubmitAt
      secretCodeSuccess
      cancelAtHost
      declineAtGuest
      declineAtHost
      hostPayoutId
      chat {
        items {
          id
          createdAt
          from
          to
          toSeen
          message
        }
      }
      seenHost
      seenGuest
      createdAt
      updatedAt
    }
  }
`

export const submitPayment = /* GraphQL */ `
  mutation SubmitPayment(
    $paymentTokenId: String!
    $paymentEnv: String!
    $input: UpdateBookingInput!
  ) {
    updateBooking(input: $input) {
      paymentCreate(paymentToken: $paymentTokenId, paymentEnv: $paymentEnv)
      paymentCreateAt
      paymentSuccess
    }
  }
`

export const updateBooking = /* GraphQL */ `
  mutation UpdateBooking(
    $input: UpdateBookingInput!
    $condition: ModelBookingConditionInput
  ) {
    updateBooking(input: $input, condition: $condition) {
      id
      listingId
      priceOption
      price
      duration
      currency
      adults
      children
      message
      createdAt
      updatedAt
    }
  }
`

export const bookingBoardingIDVerify = /* GraphQL */ `
  mutation BookingBoardingIDVerify(
    $secretCode: String
    $input: UpdateBookingInput!
  ) {
    updateBooking(input: $input) {
      id
      owner
      secretCodeSubmit(secretCode: $secretCode)
      secretCodeSubmitAt
      secretCodeSuccess
    }
  }
`

export const updateBookingHost = /* GraphQL */ `
  mutation UpdateBooking(
    $input: UpdateBookingInput!
    $condition: ModelBookingConditionInput
  ) {
    updateBooking(input: $input, condition: $condition) {
      id
      notify
      owner
      listingId
      hostId
      priceOption
      price
      pricePremium
      priceOptionPremium
      duration
      durationPremium
      currency
      adults
      children
      message
      departurePrimary
      departureSecondary
      departureHost
      acceptedAtHost
      acceptedAtGuest
      #paymentCreateAt //not authed causes error
      paymentCreate
      paymentSuccess
      verifySubmit
      secretCodeSubmit
      secretCodeSubmitAt
      secretCodeSuccess
      cancelAtHost
      declineAtHost
      declineAtGuest
      hostPayoutId
      seenGuest
      seenHost
      createdAt
      updatedAt
      chat {
        items {
          id
          from
          fromName
          to
          message
          createdAt
          toSeen
          updatedAt
        }
        nextToken
      }
      hostUser {
        id
        firstName
        lastName
      }
      guestUser {
        id
        firstName
        lastName
      }
      listing {
        id
        gen_title
        detailsDeparture
        detailsRules
        detailsToBring
        cancelDays
        cancelRefund
        photos {
          items {
            id
            listingCover
            enabled
          }
          nextToken
        }
      }
    }
  }
`

export const updateBookingGuest = /* GraphQL */ `
  mutation UpdateBooking(
    $input: UpdateBookingInput!
    $condition: ModelBookingConditionInput
  ) {
    updateBooking(input: $input, condition: $condition) {
      id
      owner
      listingId
      notify
      hostId
      priceOption
      price
      pricePremium
      priceOptionPremium
      duration
      durationPremium
      currency
      adults
      children
      message
      departurePrimary
      departureSecondary
      departureHost
      acceptedAtHost
      acceptedAtGuest
      paymentCreateAt
      paymentCreate
      paymentSuccess
      verifySubmit
      secretCode
      secretCodeSubmit
      secretCodeSubmitAt
      secretCodeSuccess
      cancelAtHost
      declineAtHost
      declineAtGuest
      hostPayoutId
      seenGuest
      seenHost
      createdAt
      updatedAt
      chat {
        items {
          id
          from
          fromName
          to
          message
          createdAt
          toSeen
          updatedAt
        }
        nextToken
      }
      hostUser {
        id
        firstName
        lastName
      }
      guestUser {
        id
        firstName
        lastName
      }
      listing {
        id
        gen_title
        detailsDeparture
        detailsRules
        detailsToBring
        cancelDays
        cancelRefund
        photos {
          items {
            id
            listingCover
            enabled
          }
          nextToken
        }
      }
    }
  }
`

export const deleteBooking = /* GraphQL */ `
  mutation DeleteBooking(
    $input: DeleteBookingInput!
    $condition: ModelBookingConditionInput
  ) {
    deleteBooking(input: $input, condition: $condition) {
      id
      listingId
      priceOption
      price
      duration
      currency
      adults
      children
      message
      createdAt
      updatedAt
    }
  }
`
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      firstName
      lastName
      currency
      about
      photos {
        items {
          id
          listingCover
          listingId
          userId
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      listings {
        items {
          id
          userId
          gen_title
          gen_desc
          gen_captainOnboard
          operator
          gen_type
          status
          spec_makeModel
          spec_length
          spec_year
          spec_capacity
          spec_numberEngines
          spec_horsepower
          spec_engineBrand
          spec_engineModel
          feature_BBQ
          feature_bimini
          feature_cabin
          feature_cuddyCabin
          feature_dinghy
          feature_dualForwardSeating
          feature_fishingGear
          feature_fuelTypeDiesel
          feature_galleyStoveOven
          feature_inverter
          feature_licensingRequired
          feature_singleEngine
          feature_toilet
          feature_watersportsEquipment
          feature_wiFi
          price_currency
          price_autobook
          price_1duration
          price_1
          price_2duration
          price_2
          price_3duration
          price_3
          price_4duration
          price_4
          price_5duration
          price_5
          cancelDays
          cancelRefund
          detailsRules
          detailsToBring
          detailsDeparture
          location_address
          location_lat
          location_lng
          location_vicinity
          createdAt
          updatedAt
        }
        nextToken
      }
      newsletter
      createdAt
      updatedAt
    }
  }
`
export const createUserData = /* GraphQL */ `
  mutation CreateUserData(
    $input: CreateUserDataInput!
    $condition: ModelUserDataConditionInput
  ) {
    createUserData(input: $input, condition: $condition) {
      id
      firstName
      currency
      about
      languages
      timezone
      tracking
      termsAccept
      photoPrimary
      primaryType
      newsletter
      phone
      email
      location_lat
      location_lng
      pushToken
      payoutAddress
      payoutCurrency
      payoutAccount
      payoutName
      payoutBankCode
      payoutBranchCode
      createdAt
      updatedAt
      lastName
      stripeConnect
    }
  }
`

export const updateUserData = /* GraphQL */ `
  mutation UpdateUserData(
    $input: UpdateUserDataInput!
    $condition: ModelUserDataConditionInput
  ) {
    updateUserData(input: $input, condition: $condition) {
      id
      firstName
      lastName
      currency
      about
      languages
      timezone
      termsAccept
      photoPrimary
      primaryType
      newsletter
      phone
      email
      location_lat
      location_lng
      payoutAddress
      payoutCurrency
      payoutAccount
      payoutName
      payoutBankCode
      payoutBranchCode
      createdAt
      updatedAt
      listings {
        items {
          id
          userId
          gen_title
          gen_desc
          gen_captainOnboard
          operator
          gen_type
          status
          spec_makeModel
          spec_length
          spec_year
          spec_capacity
          spec_numberEngines
          spec_horsepower
          spec_engineBrand
          spec_engineModel
          feature_BBQ
          feature_bimini
          feature_cabin
          feature_cuddyCabin
          feature_dinghy
          feature_dualForwardSeating
          feature_fishingGear
          feature_fuelTypeDiesel
          feature_galleyStoveOven
          feature_inverter
          feature_licensingRequired
          feature_singleEngine
          feature_toilet
          feature_watersportsEquipment
          feature_wiFi
          price_currency
          price_autobook
          price_1duration
          price_1
          price_1premium
          price_2duration
          price_2
          price_2premium
          price_3duration
          price_3
          price_3premium
          price_4duration
          price_4
          price_4premium
          price_5duration
          price_5
          price_5premium
          cancelDays
          cancelRefund
          detailsRules
          detailsToBring
          detailsDeparture
          location_address
          location_vicinity
          createdAt
          updatedAt
          reviews {
            items {
              id
              listingId
              bookingId
              userId
              rating
              desc
              createdAt
              updatedAt
            }
            nextToken
          }
          photos {
            items {
              id
              listingCover
              listingId
              userId
              fileType
              photoType
              createdAt
              updatedAt
              enabled
            }
            nextToken
          }
        }
        total
        nextToken
      }
      photos {
        items {
          id
          listingCover
          listingId
          userId
          fileType
          photoType
          size
          height
          width
          enabled
          createdAt
          updatedAt
        }
        nextToken
      }
      guestBookings {
        items {
          id
          owner
          listingId
          hostId
          priceOption
          price
          duration
          priceOptionPremium
          pricePremium
          durationPremium
          currency
          adults
          children
          message
          departurePrimary
          departurePrimaryPremium
          departureSecondary
          departureSecondaryPremium
          departureHost
          acceptedAtHost
          acceptedAtGuest
          paymentCreateAt
          paymentCreate
          paymentSuccess
          verifySubmit
          secretCode
          secretCodeSubmit
          secretCodeSubmitAt
          secretCodeSuccess
          cancelAtHost
          declineAtHost
          declineAtGuest
          hostPayoutId
          seenGuest
          seenHost
          createdAt
          updatedAt
        }
        nextToken
      }
      payouts {
        items {
          id
          userId
          status
          targetPayoutDate
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`

export const updateUserDataTracking = /* GraphQL */ `
  mutation UpdateUserData(
    $input: UpdateUserDataInput!
    $condition: ModelUserDataConditionInput
  ) {
    updateUserData(input: $input, condition: $condition) {
      id
      tracking
    }
  }
`

export const deleteUser = /* GraphQL */ `
  mutation DeleteUserData(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUserData(input: $input, condition: $condition) {
      id
      firstName
      lastName
      currency
      about
      photos {
        items {
          id
          listingCover
          listingId
          userId
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      listings {
        items {
          id
          userId
          gen_title
          gen_desc
          gen_captainOnboard
          operator
          gen_type
          status
          spec_makeModel
          spec_length
          spec_year
          spec_capacity
          spec_numberEngines
          spec_horsepower
          spec_engineBrand
          spec_engineModel
          feature_BBQ
          feature_bimini
          feature_cabin
          feature_cuddyCabin
          feature_dinghy
          feature_dualForwardSeating
          feature_fishingGear
          feature_fuelTypeDiesel
          feature_galleyStoveOven
          feature_inverter
          feature_licensingRequired
          feature_singleEngine
          feature_toilet
          feature_watersportsEquipment
          feature_wiFi
          price_currency
          price_autobook
          price_1duration
          price_1
          price_2duration
          price_2
          price_3duration
          price_3
          price_4duration
          price_4
          price_5duration
          price_5
          cancelDays
          cancelRefund
          detailsRules
          detailsToBring
          detailsDeparture
          location_address
          location_lat
          location_lng
          location_vicinity
          createdAt
          updatedAt
        }
        nextToken
      }
      newsletter
      createdAt
      updatedAt
    }
  }
`
export const createReview = /* GraphQL */ `
  mutation CreateReview(
    $input: CreateReviewInput!
    $condition: ModelReviewConditionInput
  ) {
    createReview(input: $input, condition: $condition) {
      id
      listingId
      bookingId
      userId
      rating
      desc
      createdAt
      updatedAt
    }
  }
`
export const updateReview = /* GraphQL */ `
  mutation UpdateReview(
    $input: UpdateReviewInput!
    $condition: ModelReviewConditionInput
  ) {
    updateReview(input: $input, condition: $condition) {
      id
      listingId
      bookingId
      userId
      rating
      desc
      createdAt
      updatedAt
    }
  }
`
export const deleteReview = /* GraphQL */ `
  mutation DeleteReview(
    $input: DeleteReviewInput!
    $condition: ModelReviewConditionInput
  ) {
    deleteReview(input: $input, condition: $condition) {
      id
      listingId
      bookingId
      userId
      rating
      desc
      createdAt
      updatedAt
    }
  }
`

export const newListingState = {
  id: "",
  userId: "",
  gen_title: "",
  gen_desc: "",
  gen_captainOnboard: true,
  gen_type: "POWERBOAT",
  status: "ACTIVE",
  spec_makeModel: "",
  spec_length: null,
  spec_year: null,
  spec_capacity: 2,
  spec_numberEngines: null,
  spec_horsepower: null,
  spec_engineBrand: "",
  spec_engineModel: "",
  feature_BBQ: false,
  feature_bimini: false,
  feature_cabin: false,
  feature_cuddyCabin: false,
  feature_dinghy: false,
  feature_dualForwardSeating: false,
  feature_fishingGear: false,
  feature_fuelTypeDiesel: false,
  feature_galleyStoveOven: false,
  feature_inverter: false,
  feature_licensingRequired: false,
  feature_singleEngine: false,
  feature_toilet: false,
  feature_watersportsEquipment: false,
  feature_wiFi: false,
  price_currency: "USD",
  price_autobook: false,
  timezone: null,
  price_1duration: 3,
  price_1: null,
  price_1premium: null,
  price_2duration: 4,
  price_2: null,
  price_2premium: null,
  price_3duration: 5,
  price_3: null,
  price_3premium: null,
  price_4duration: undefined,
  price_4: undefined,
  price_4premium: undefined,
  price_5duration: undefined,
  price_5: undefined,
  price_5premium: undefined,
  cancelDays: "FIVE",
  cancelRefund: "FULL",
  detailsRules: "",
  detailsToBring: "",
  detailsDeparture: "",
  location_address: "",
  location: { lat: "", lon: "" },
  location_vicinity: "",
  photos: undefined,
}

export default newListingState

import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit"
import { API, Auth, graphqlOperation } from "aws-amplify"
import { nearbyListings, getListing } from "../graphqlEdited/queries"
import { createListing, updateListing } from "../graphql/mutations"
import newListingState from "../components/add/addNewListing"
import { makeUnauthUser } from "./userSlice"

const initialState = {
  items: [],
  userItems: [],
  status: "idle",
  error: null,
  createStatus: "idle",
  listingForBooking: {},
  new: {},
}

export const ListingsNearBy = createAsyncThunk(
  "listings/ListingsNearBy",
  async ({ authed, variables }, { rejectWithValue, dispatch }) => {
    try {
      const {
        payload: { authenticated },
      } = await dispatch(makeUnauthUser())

      return await API.graphql({
        query: nearbyListings,
        variables,
        authMode: authenticated ? "AMAZON_COGNITO_USER_POOLS" : "AWS_IAM",
      })
    } catch (err) {
      console.error("listings/ListingsNearBy", err)
      return rejectWithValue(err)
    }
  }
)

export const ListingUpdate = createAsyncThunk(
  "listings/ListingUpdate",
  async ({ data, cb }, { rejectWithValue }) => {
    try {
      //handle users accepted terms
      // if (data.acceptTerms) {

      let requestData = { ...data }

      if ("acceptTerms" in data) {
        delete requestData.acceptTerms
      }

      if ("photos" in data) {
        delete requestData.photos
      }

      // }

      const response = await API.graphql(
        graphqlOperation(updateListing, {
          input: requestData,
        })
      )

      if (cb) {
        cb()
      }

      return response
    } catch (err) {
      console.error("listings/ListingUpdate", err)
      return rejectWithValue(err)
    }
  }
)

export const ListingCreate = createAsyncThunk(
  "listings/ListingCreate",
  async ({ data, cb }, { rejectWithValue }) => {
    try {
      //handle users accepted terms
      // if (data.acceptTerms) {

      const requiredListingFields = ["id", "userId", "status"]

      const submitData = { ...data }
      const hasAllFields = requiredListingFields.every(fieldName =>
        submitData.hasOwnProperty(fieldName)
      )
      if (!hasAllFields) {
        throw Error("Not all fields here")
      } else {
      }

      if ("acceptTerms" in submitData) {
        delete submitData.acceptTerms
      }

      const response = await API.graphql(
        graphqlOperation(createListing, {
          input: submitData,
        })
      )

      if (cb) {
        cb()
      }

      return response
    } catch (err) {
      console.error("listings/ListingCreate", err)
      return rejectWithValue(err)
    }
  }
)

export const ListingGet = createAsyncThunk(
  "listings/ListingGet",
  async ({ listingId }, { rejectWithValue, dispatch }) => {
    try {
      const {
        payload: { authenticated },
      } = await dispatch(makeUnauthUser())
      const response = await API.graphql({
        query: getListing,
        variables: { id: listingId },
        authMode: authenticated ? "AMAZON_COGNITO_USER_POOLS" : "AWS_IAM",
      })

      if (!response?.data?.getListing) {
        throw new Error("No data yet")
      }
      return response
    } catch (err) {
      console.error("listings/ListingGet", err)
      return rejectWithValue(err)
    }
  }
)

const listingsSlice = createSlice({
  name: "listings",
  initialState,
  reducers: {
    startNew: (state, action) => {
      state.createStatus = "idle"
      state.new.id = action.payload.id
      state.new.type = action.payload.type
    },
    location: (state, action) => {
      state.new = {
        ...state.new,
        location_address: action.payload[1].formatted_address,
        location_lat: action.payload[1].geometry.location.lat,
        location_lng: action.payload[1].geometry.location.lng,
        location_vicinity: action.payload[1].vicinity,
      }
    },
    ListingStepSubmit: (state, action) => {
      state.createStatus = "idle"
    },
    ListingResetStatus: state => {
      state.createStatus = "idle"
      state.status = "idle"
    },
    ListingPhotoDisable: (state, action) => {
      state.new.photos.map(item => {
        if (item.id === action.payload.id) {
          item.enabled = false
          item.listingCover = false
        }
      })
    },
    // ListingPhotosReplace: (state, action) => {
    //   const listing = state.items.find(item => item.id === action.payload.id)
    //   if (listing) {
    //     listing.photos.items = action.payload.photos
    //   }
    // },
    ListingPhotoAdd: (state, action) => {
      state.new.photos = state.new.photos.concat(action.payload)
    },
    ListingPhotoSetPrimary: (state, action) => {
      state.new.photos.map(item =>
        item.id === action.payload.id
          ? (item.listingCover = true)
          : (item.listingCover = false)
      )
    },
    // ListingPhotoAutoSetPrimary: (state, action) => {
    //   //after primary photo deleted need to set another one.
    //   const primaryTest = state.new.photos.find(
    //     item => item.listingCover && item.enabled
    //   )
    //   if (!primaryTest) {
    //     const enabledPhoto = state.new.photos.findIndex(item => item.enabled)
    //     if (enabledPhoto !== -1) {
    //       state.new.photos[enabledPhoto].listingCover = true
    //     }
    //   }
    // },
    addUserListings: (state, action) => {
      const { items } = action.payload.data.getUserData.listings
      state.userItems = items
      state.items.concat(items)
    },
    ClearListings: (state, action) => {
      const { userItems } = current(state)
      state.items = [...userItems]
    },
    ListingsAddIfDontExist: (state, action) => {
      const { userItems, items: itemsState } = current(state)
      const { items, source } = action.payload
      if (source === "LISTING_PAGE") {
        state.items = [...itemsState, ...items]
      } else if (userItems) {
        //there might be duplicates
        state.items = [...userItems, ...items]
      } else {
        state.items = items
      }
    },
  },
  extraReducers: {
    [ListingCreate.fulfilled]: (state, action) => {
      //when a new listing is made
      //add the listing to listings
      // state.items = [...state.items, action.payload.data.createListing];
      state.createStatus = "ADD_LISTING_SUCCESS"
    },
    [ListingCreate.rejected]: (state, action) => {
      state.createStatus = "rejected"
    },
    [ListingCreate.pending]: (state, action) => {
      state.createStatus = "loading"
    },
    [ListingUpdate.fulfilled]: (state, action) => {
      let updatedState = action?.payload?.data?.updateListing
      if (updatedState) {
        delete updatedState.bookings
        delete updatedState.reviews
        delete updatedState.user
        state.new = { ...state.new, ...updatedState }
      }
    },
    [ListingUpdate.rejected]: (state, action) => {
      state.createStatus = "rejected"
    },
    [ListingUpdate.pending]: (state, action) => {
      state.createStatus = "loading"
    },
    [ListingsNearBy.pending]: (state, action) => {
      state.status = "loading"
    },
    [ListingsNearBy.fulfilled]: (state, action) => {
      const { init } = action.meta.arg
      let newItems = []
      const resItems = action.payload.data.nearbyListingsKm?.items.filter(
        item => item.status === "ACTIVE"
      )

      //if API return no results
      if (!resItems || !resItems.length) {
        newItems = state.items
      } else {
        const oldItems = state.items.filter(
          item => !resItems.some(newItem => newItem.id === item.id)
        )
        newItems = oldItems.concat(resItems)
      }

      state.items = newItems
      state.status = "succeeded"
    },
    [ListingsNearBy.rejected]: (state, action) => {
      state.status = "rejected"
    },

    [ListingGet.pending]: (state, action) => {
      state.status = "loading"
    },
    [ListingGet.fulfilled]: (state, action) => {
      try {
        if (action.meta.arg.source === "ADD_LISTING") {
          const result = {}
          for (const key of Object.keys(newListingState)) {
            if (action.payload.data.getListing.hasOwnProperty(key)) {
              result[key] = action.payload.data.getListing[key]
            } else {
              result[key] = undefined
            }
          }
          state.new = result
          state.status = "ADD_LISTING_FETCHED"
        } else if (action.meta.arg.source === "BOOKING_REQUEST") {
          state.listingForBooking = action.payload.data.getListing
          state.status = "succeeded"
        } else {
          state.status = "succeeded"
        }
      } catch (err) {} // const resp = action.payload.data.getListing;
      // const nextItems = state.items.filter(item => item.id !== resp.id);
      // state.items = [...nextItems, resp];
    },
    [ListingGet.rejected]: (state, action) => {
      state.status = "rejected"
      if (action.meta.arg.source === "ADD_LISTING") {
        state.status = "ADD_LISTING_FETCH_REJECTED"
        //if rejected setup form with default values
      }
    },
  },
})

export const {
  startNew,
  location,
  ListingResetStatus,
  ListingStepSubmit,
  ListingsAddIfDontExist,
  addUserListings,
} = listingsSlice.actions

export default listingsSlice.reducer

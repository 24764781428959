/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateListing = /* GraphQL */ `
  subscription OnCreateListing {
    onCreateListing {
      id
      userId
      gen_title
      gen_desc
      timezone
      gen_captainOnboard
      operator
      gen_type
      status
      spec_makeModel
      spec_length
      spec_year
      spec_capacity
      spec_numberEngines
      spec_horsepower
      spec_engineBrand
      spec_engineModel
      feature_BBQ
      feature_bimini
      feature_cabin
      feature_cuddyCabin
      feature_dinghy
      feature_dualForwardSeating
      feature_fishingGear
      feature_fuelTypeDiesel
      feature_galleyStoveOven
      feature_inverter
      feature_licensingRequired
      feature_singleEngine
      feature_toilet
      feature_watersportsEquipment
      feature_wiFi
      price_currency
      price_autobook
      price_1duration
      price_1
      price_1premium
      price_2duration
      price_2
      price_2premium
      price_3duration
      price_3
      price_3premium
      price_4duration
      price_4
      price_4premium
      price_5duration
      price_5
      price_5premium
      cancelDays
      cancelRefund
      detailsRules
      detailsToBring
      detailsDeparture
      location_address
      location {
        lat
        lon
        __typename
      }
      location_vicinity
      sort
      createdAt
      updatedAt
      photos {
        nextToken
        __typename
      }
      bookings {
        nextToken
        __typename
      }
      user {
        id
        firstName
        currency
        about
        languages
        timezone
        tracking
        termsAccept
        photoPrimary
        primaryType
        newsletter
        phone
        email
        location_lat
        location_lng
        pushToken
        payoutAddress
        payoutCurrency
        payoutAccount
        payoutName
        payoutBankCode
        payoutBranchCode
        stripeConnect
        stripeConnectPK
        createdAt
        updatedAt
        lastName
        __typename
      }
      reviews {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateListing = /* GraphQL */ `
  subscription OnUpdateListing {
    onUpdateListing {
      id
      userId
      gen_title
      gen_desc
      timezone
      gen_captainOnboard
      operator
      gen_type
      status
      spec_makeModel
      spec_length
      spec_year
      spec_capacity
      spec_numberEngines
      spec_horsepower
      spec_engineBrand
      spec_engineModel
      feature_BBQ
      feature_bimini
      feature_cabin
      feature_cuddyCabin
      feature_dinghy
      feature_dualForwardSeating
      feature_fishingGear
      feature_fuelTypeDiesel
      feature_galleyStoveOven
      feature_inverter
      feature_licensingRequired
      feature_singleEngine
      feature_toilet
      feature_watersportsEquipment
      feature_wiFi
      price_currency
      price_autobook
      price_1duration
      price_1
      price_1premium
      price_2duration
      price_2
      price_2premium
      price_3duration
      price_3
      price_3premium
      price_4duration
      price_4
      price_4premium
      price_5duration
      price_5
      price_5premium
      cancelDays
      cancelRefund
      detailsRules
      detailsToBring
      detailsDeparture
      location_address
      location {
        lat
        lon
        __typename
      }
      location_vicinity
      sort
      createdAt
      updatedAt
      photos {
        nextToken
        __typename
      }
      bookings {
        nextToken
        __typename
      }
      user {
        id
        firstName
        currency
        about
        languages
        timezone
        tracking
        termsAccept
        photoPrimary
        primaryType
        newsletter
        phone
        email
        location_lat
        location_lng
        pushToken
        payoutAddress
        payoutCurrency
        payoutAccount
        payoutName
        payoutBankCode
        payoutBranchCode
        stripeConnect
        stripeConnectPK
        createdAt
        updatedAt
        lastName
        __typename
      }
      reviews {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteListing = /* GraphQL */ `
  subscription OnDeleteListing {
    onDeleteListing {
      id
      userId
      gen_title
      gen_desc
      timezone
      gen_captainOnboard
      operator
      gen_type
      status
      spec_makeModel
      spec_length
      spec_year
      spec_capacity
      spec_numberEngines
      spec_horsepower
      spec_engineBrand
      spec_engineModel
      feature_BBQ
      feature_bimini
      feature_cabin
      feature_cuddyCabin
      feature_dinghy
      feature_dualForwardSeating
      feature_fishingGear
      feature_fuelTypeDiesel
      feature_galleyStoveOven
      feature_inverter
      feature_licensingRequired
      feature_singleEngine
      feature_toilet
      feature_watersportsEquipment
      feature_wiFi
      price_currency
      price_autobook
      price_1duration
      price_1
      price_1premium
      price_2duration
      price_2
      price_2premium
      price_3duration
      price_3
      price_3premium
      price_4duration
      price_4
      price_4premium
      price_5duration
      price_5
      price_5premium
      cancelDays
      cancelRefund
      detailsRules
      detailsToBring
      detailsDeparture
      location_address
      location {
        lat
        lon
        __typename
      }
      location_vicinity
      sort
      createdAt
      updatedAt
      photos {
        nextToken
        __typename
      }
      bookings {
        nextToken
        __typename
      }
      user {
        id
        firstName
        currency
        about
        languages
        timezone
        tracking
        termsAccept
        photoPrimary
        primaryType
        newsletter
        phone
        email
        location_lat
        location_lng
        pushToken
        payoutAddress
        payoutCurrency
        payoutAccount
        payoutName
        payoutBankCode
        payoutBranchCode
        stripeConnect
        stripeConnectPK
        createdAt
        updatedAt
        lastName
        __typename
      }
      reviews {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onCreatePhoto = /* GraphQL */ `
  subscription OnCreatePhoto {
    onCreatePhoto {
      id
      listingCover
      listingId
      userId
      fileType
      photoType
      size
      height
      width
      sort
      enabled
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdatePhoto = /* GraphQL */ `
  subscription OnUpdatePhoto {
    onUpdatePhoto {
      id
      listingCover
      listingId
      userId
      fileType
      photoType
      size
      height
      width
      sort
      enabled
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeletePhoto = /* GraphQL */ `
  subscription OnDeletePhoto {
    onDeletePhoto {
      id
      listingCover
      listingId
      userId
      fileType
      photoType
      size
      height
      width
      sort
      enabled
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateContactUs = /* GraphQL */ `
  subscription OnCreateContactUs($owner: String) {
    onCreateContactUs(owner: $owner) {
      id
      owner
      name
      email
      message
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateContactUs = /* GraphQL */ `
  subscription OnUpdateContactUs($owner: String) {
    onUpdateContactUs(owner: $owner) {
      id
      owner
      name
      email
      message
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteContactUs = /* GraphQL */ `
  subscription OnDeleteContactUs($owner: String) {
    onDeleteContactUs(owner: $owner) {
      id
      owner
      name
      email
      message
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateBooking = /* GraphQL */ `
  subscription OnCreateBooking($owner: String, $hostId: String) {
    onCreateBooking(owner: $owner, hostId: $hostId) {
      id
      owner
      listingId
      hostId
      priceOption
      price
      duration
      priceOptionPremium
      pricePremium
      fees
      durationPremium
      currency
      adults
      children
      message
      timezone
      notify
      chatBlocked
      departurePrimary
      departurePrimaryPremium
      departureSecondary
      departureSecondaryPremium
      departureHost
      acceptedAtHost
      acceptedAtGuest
      paymentInfo
      paymentCreateAt
      paymentCreate
      paymentIntentStripe
      paymentSuccess
      verifySubmit
      secretCode
      secretCodeSubmit
      secretCodeSubmitAt
      secretCodeSuccess
      cancelAtHost
      declineAtHost
      declineAtGuest
      hostPayoutId
      seenGuest
      seenHost
      createdAt
      updatedAt
      listing {
        id
        userId
        gen_title
        gen_desc
        timezone
        gen_captainOnboard
        operator
        gen_type
        status
        spec_makeModel
        spec_length
        spec_year
        spec_capacity
        spec_numberEngines
        spec_horsepower
        spec_engineBrand
        spec_engineModel
        feature_BBQ
        feature_bimini
        feature_cabin
        feature_cuddyCabin
        feature_dinghy
        feature_dualForwardSeating
        feature_fishingGear
        feature_fuelTypeDiesel
        feature_galleyStoveOven
        feature_inverter
        feature_licensingRequired
        feature_singleEngine
        feature_toilet
        feature_watersportsEquipment
        feature_wiFi
        price_currency
        price_autobook
        price_1duration
        price_1
        price_1premium
        price_2duration
        price_2
        price_2premium
        price_3duration
        price_3
        price_3premium
        price_4duration
        price_4
        price_4premium
        price_5duration
        price_5
        price_5premium
        cancelDays
        cancelRefund
        detailsRules
        detailsToBring
        detailsDeparture
        location_address
        location_vicinity
        sort
        createdAt
        updatedAt
        __typename
      }
      guestUser {
        id
        firstName
        currency
        about
        languages
        timezone
        tracking
        termsAccept
        photoPrimary
        primaryType
        newsletter
        phone
        email
        location_lat
        location_lng
        pushToken
        payoutAddress
        payoutCurrency
        payoutAccount
        payoutName
        payoutBankCode
        payoutBranchCode
        stripeConnect
        stripeConnectPK
        createdAt
        updatedAt
        lastName
        __typename
      }
      hostUser {
        id
        firstName
        currency
        about
        languages
        timezone
        tracking
        termsAccept
        photoPrimary
        primaryType
        newsletter
        phone
        email
        location_lat
        location_lng
        pushToken
        payoutAddress
        payoutCurrency
        payoutAccount
        payoutName
        payoutBankCode
        payoutBranchCode
        stripeConnect
        stripeConnectPK
        createdAt
        updatedAt
        lastName
        __typename
      }
      chat {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateBooking = /* GraphQL */ `
  subscription OnUpdateBooking($owner: String, $hostId: String) {
    onUpdateBooking(owner: $owner, hostId: $hostId) {
      id
      owner
      listingId
      hostId
      priceOption
      price
      duration
      priceOptionPremium
      pricePremium
      fees
      durationPremium
      currency
      adults
      children
      message
      timezone
      notify
      chatBlocked
      departurePrimary
      departurePrimaryPremium
      departureSecondary
      departureSecondaryPremium
      departureHost
      acceptedAtHost
      acceptedAtGuest
      paymentInfo
      paymentCreateAt
      paymentCreate
      paymentIntentStripe
      paymentSuccess
      verifySubmit
      secretCode
      secretCodeSubmit
      secretCodeSubmitAt
      secretCodeSuccess
      cancelAtHost
      declineAtHost
      declineAtGuest
      hostPayoutId
      seenGuest
      seenHost
      createdAt
      updatedAt
      listing {
        id
        userId
        gen_title
        gen_desc
        timezone
        gen_captainOnboard
        operator
        gen_type
        status
        spec_makeModel
        spec_length
        spec_year
        spec_capacity
        spec_numberEngines
        spec_horsepower
        spec_engineBrand
        spec_engineModel
        feature_BBQ
        feature_bimini
        feature_cabin
        feature_cuddyCabin
        feature_dinghy
        feature_dualForwardSeating
        feature_fishingGear
        feature_fuelTypeDiesel
        feature_galleyStoveOven
        feature_inverter
        feature_licensingRequired
        feature_singleEngine
        feature_toilet
        feature_watersportsEquipment
        feature_wiFi
        price_currency
        price_autobook
        price_1duration
        price_1
        price_1premium
        price_2duration
        price_2
        price_2premium
        price_3duration
        price_3
        price_3premium
        price_4duration
        price_4
        price_4premium
        price_5duration
        price_5
        price_5premium
        cancelDays
        cancelRefund
        detailsRules
        detailsToBring
        detailsDeparture
        location_address
        location_vicinity
        sort
        createdAt
        updatedAt
        __typename
      }
      guestUser {
        id
        firstName
        currency
        about
        languages
        timezone
        tracking
        termsAccept
        photoPrimary
        primaryType
        newsletter
        phone
        email
        location_lat
        location_lng
        pushToken
        payoutAddress
        payoutCurrency
        payoutAccount
        payoutName
        payoutBankCode
        payoutBranchCode
        stripeConnect
        stripeConnectPK
        createdAt
        updatedAt
        lastName
        __typename
      }
      hostUser {
        id
        firstName
        currency
        about
        languages
        timezone
        tracking
        termsAccept
        photoPrimary
        primaryType
        newsletter
        phone
        email
        location_lat
        location_lng
        pushToken
        payoutAddress
        payoutCurrency
        payoutAccount
        payoutName
        payoutBankCode
        payoutBranchCode
        stripeConnect
        stripeConnectPK
        createdAt
        updatedAt
        lastName
        __typename
      }
      chat {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteBooking = /* GraphQL */ `
  subscription OnDeleteBooking($owner: String, $hostId: String) {
    onDeleteBooking(owner: $owner, hostId: $hostId) {
      id
      owner
      listingId
      hostId
      priceOption
      price
      duration
      priceOptionPremium
      pricePremium
      fees
      durationPremium
      currency
      adults
      children
      message
      timezone
      notify
      chatBlocked
      departurePrimary
      departurePrimaryPremium
      departureSecondary
      departureSecondaryPremium
      departureHost
      acceptedAtHost
      acceptedAtGuest
      paymentInfo
      paymentCreateAt
      paymentCreate
      paymentIntentStripe
      paymentSuccess
      verifySubmit
      secretCode
      secretCodeSubmit
      secretCodeSubmitAt
      secretCodeSuccess
      cancelAtHost
      declineAtHost
      declineAtGuest
      hostPayoutId
      seenGuest
      seenHost
      createdAt
      updatedAt
      listing {
        id
        userId
        gen_title
        gen_desc
        timezone
        gen_captainOnboard
        operator
        gen_type
        status
        spec_makeModel
        spec_length
        spec_year
        spec_capacity
        spec_numberEngines
        spec_horsepower
        spec_engineBrand
        spec_engineModel
        feature_BBQ
        feature_bimini
        feature_cabin
        feature_cuddyCabin
        feature_dinghy
        feature_dualForwardSeating
        feature_fishingGear
        feature_fuelTypeDiesel
        feature_galleyStoveOven
        feature_inverter
        feature_licensingRequired
        feature_singleEngine
        feature_toilet
        feature_watersportsEquipment
        feature_wiFi
        price_currency
        price_autobook
        price_1duration
        price_1
        price_1premium
        price_2duration
        price_2
        price_2premium
        price_3duration
        price_3
        price_3premium
        price_4duration
        price_4
        price_4premium
        price_5duration
        price_5
        price_5premium
        cancelDays
        cancelRefund
        detailsRules
        detailsToBring
        detailsDeparture
        location_address
        location_vicinity
        sort
        createdAt
        updatedAt
        __typename
      }
      guestUser {
        id
        firstName
        currency
        about
        languages
        timezone
        tracking
        termsAccept
        photoPrimary
        primaryType
        newsletter
        phone
        email
        location_lat
        location_lng
        pushToken
        payoutAddress
        payoutCurrency
        payoutAccount
        payoutName
        payoutBankCode
        payoutBranchCode
        stripeConnect
        stripeConnectPK
        createdAt
        updatedAt
        lastName
        __typename
      }
      hostUser {
        id
        firstName
        currency
        about
        languages
        timezone
        tracking
        termsAccept
        photoPrimary
        primaryType
        newsletter
        phone
        email
        location_lat
        location_lng
        pushToken
        payoutAddress
        payoutCurrency
        payoutAccount
        payoutName
        payoutBankCode
        payoutBranchCode
        stripeConnect
        stripeConnectPK
        createdAt
        updatedAt
        lastName
        __typename
      }
      chat {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onCreateUserData = /* GraphQL */ `
  subscription OnCreateUserData {
    onCreateUserData {
      id
      firstName
      currency
      about
      languages
      timezone
      tracking
      termsAccept
      photoPrimary
      primaryType
      newsletter
      phone
      email
      location_lat
      location_lng
      pushToken
      payoutAddress
      payoutCurrency
      payoutAccount
      payoutName
      payoutBankCode
      payoutBranchCode
      stripeConnect
      stripeConnectPK
      createdAt
      updatedAt
      listings {
        total
        nextToken
        __typename
      }
      photos {
        nextToken
        __typename
      }
      guestBookings {
        nextToken
        __typename
      }
      lastName
      reviews {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateUserData = /* GraphQL */ `
  subscription OnUpdateUserData {
    onUpdateUserData {
      id
      firstName
      currency
      about
      languages
      timezone
      tracking
      termsAccept
      photoPrimary
      primaryType
      newsletter
      phone
      email
      location_lat
      location_lng
      pushToken
      payoutAddress
      payoutCurrency
      payoutAccount
      payoutName
      payoutBankCode
      payoutBranchCode
      stripeConnect
      stripeConnectPK
      createdAt
      updatedAt
      listings {
        total
        nextToken
        __typename
      }
      photos {
        nextToken
        __typename
      }
      guestBookings {
        nextToken
        __typename
      }
      lastName
      reviews {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteUserData = /* GraphQL */ `
  subscription OnDeleteUserData {
    onDeleteUserData {
      id
      firstName
      currency
      about
      languages
      timezone
      tracking
      termsAccept
      photoPrimary
      primaryType
      newsletter
      phone
      email
      location_lat
      location_lng
      pushToken
      payoutAddress
      payoutCurrency
      payoutAccount
      payoutName
      payoutBankCode
      payoutBranchCode
      stripeConnect
      stripeConnectPK
      createdAt
      updatedAt
      listings {
        total
        nextToken
        __typename
      }
      photos {
        nextToken
        __typename
      }
      guestBookings {
        nextToken
        __typename
      }
      lastName
      reviews {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onCreateReview = /* GraphQL */ `
  subscription OnCreateReview {
    onCreateReview {
      id
      listingId
      bookingId
      userId
      rating
      desc
      createdAt
      updatedAt
      user {
        id
        firstName
        currency
        about
        languages
        timezone
        tracking
        termsAccept
        photoPrimary
        primaryType
        newsletter
        phone
        email
        location_lat
        location_lng
        pushToken
        payoutAddress
        payoutCurrency
        payoutAccount
        payoutName
        payoutBankCode
        payoutBranchCode
        stripeConnect
        stripeConnectPK
        createdAt
        updatedAt
        lastName
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateReview = /* GraphQL */ `
  subscription OnUpdateReview {
    onUpdateReview {
      id
      listingId
      bookingId
      userId
      rating
      desc
      createdAt
      updatedAt
      user {
        id
        firstName
        currency
        about
        languages
        timezone
        tracking
        termsAccept
        photoPrimary
        primaryType
        newsletter
        phone
        email
        location_lat
        location_lng
        pushToken
        payoutAddress
        payoutCurrency
        payoutAccount
        payoutName
        payoutBankCode
        payoutBranchCode
        stripeConnect
        stripeConnectPK
        createdAt
        updatedAt
        lastName
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteReview = /* GraphQL */ `
  subscription OnDeleteReview {
    onDeleteReview {
      id
      listingId
      bookingId
      userId
      rating
      desc
      createdAt
      updatedAt
      user {
        id
        firstName
        currency
        about
        languages
        timezone
        tracking
        termsAccept
        photoPrimary
        primaryType
        newsletter
        phone
        email
        location_lat
        location_lng
        pushToken
        payoutAddress
        payoutCurrency
        payoutAccount
        payoutName
        payoutBankCode
        payoutBranchCode
        stripeConnect
        stripeConnectPK
        createdAt
        updatedAt
        lastName
        __typename
      }
      __typename
    }
  }
`;
export const onCreatePayment = /* GraphQL */ `
  subscription OnCreatePayment($hostId: String) {
    onCreatePayment(hostId: $hostId) {
      id
      bookingId
      hostId
      payoutId
      amount
      feeHostAmount
      feeHostPercent
      feeGuestAmount
      feeGuestPercent
      name
      cardLast4
      cardType
      expiration
      address1
      address2
      city
      province
      postal
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdatePayment = /* GraphQL */ `
  subscription OnUpdatePayment($hostId: String) {
    onUpdatePayment(hostId: $hostId) {
      id
      bookingId
      hostId
      payoutId
      amount
      feeHostAmount
      feeHostPercent
      feeGuestAmount
      feeGuestPercent
      name
      cardLast4
      cardType
      expiration
      address1
      address2
      city
      province
      postal
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeletePayment = /* GraphQL */ `
  subscription OnDeletePayment($hostId: String) {
    onDeletePayment(hostId: $hostId) {
      id
      bookingId
      hostId
      payoutId
      amount
      feeHostAmount
      feeHostPercent
      feeGuestAmount
      feeGuestPercent
      name
      cardLast4
      cardType
      expiration
      address1
      address2
      city
      province
      postal
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateHostPayout = /* GraphQL */ `
  subscription OnCreateHostPayout($userId: String) {
    onCreateHostPayout(userId: $userId) {
      id
      userId
      status
      targetPayoutDate
      createdAt
      updatedAt
      payments {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateHostPayout = /* GraphQL */ `
  subscription OnUpdateHostPayout($userId: String) {
    onUpdateHostPayout(userId: $userId) {
      id
      userId
      status
      targetPayoutDate
      createdAt
      updatedAt
      payments {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteHostPayout = /* GraphQL */ `
  subscription OnDeleteHostPayout($userId: String) {
    onDeleteHostPayout(userId: $userId) {
      id
      userId
      status
      targetPayoutDate
      createdAt
      updatedAt
      payments {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onCreateChat = /* GraphQL */ `
  subscription OnCreateChat($from: String, $to: String) {
    onCreateChat(from: $from, to: $to) {
      id
      from
      fromName
      to
      toSeen
      message
      createdAt
      notify
      blocked
      updatedAt
      __typename
    }
  }
`;
export const onUpdateChat = /* GraphQL */ `
  subscription OnUpdateChat($from: String, $to: String) {
    onUpdateChat(from: $from, to: $to) {
      id
      from
      fromName
      to
      toSeen
      message
      createdAt
      notify
      blocked
      updatedAt
      __typename
    }
  }
`;
export const onDeleteChat = /* GraphQL */ `
  subscription OnDeleteChat($from: String, $to: String) {
    onDeleteChat(from: $from, to: $to) {
      id
      from
      fromName
      to
      toSeen
      message
      createdAt
      notify
      blocked
      updatedAt
      __typename
    }
  }
`;
export const onCreateEmailTemplate = /* GraphQL */ `
  subscription OnCreateEmailTemplate {
    onCreateEmailTemplate {
      id
      name
      subject
      html
      template
      published
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateEmailTemplate = /* GraphQL */ `
  subscription OnUpdateEmailTemplate {
    onUpdateEmailTemplate {
      id
      name
      subject
      html
      template
      published
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteEmailTemplate = /* GraphQL */ `
  subscription OnDeleteEmailTemplate {
    onDeleteEmailTemplate {
      id
      name
      subject
      html
      template
      published
      createdAt
      updatedAt
      __typename
    }
  }
`;

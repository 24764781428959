import { BookingNewReset, BookingResetStatus } from "../slices/bookingSlice"
import { getCurrentAuthUser, resetStatus } from "../slices/userSlice"
import { resetToast } from "../slices/toastSlice"
import { ListingResetStatus } from "../slices/listingsSlice"
// import { LocationUpdate } from "../slices/locationSlice"

export const ResetStatus = ({ dispatch, getState }) => next => action => {
  next(action)
  if (action.type === "persist/REHYDRATE") {
    dispatch(BookingResetStatus()) //Oauth lands user back to the site
    dispatch(resetStatus())
    dispatch(resetToast())
    dispatch(ListingResetStatus())
    dispatch(BookingNewReset())
    dispatch(getCurrentAuthUser())
  }
}

export const PersistMdl = [ResetStatus]

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { API, graphqlOperation } from "aws-amplify"
import { updateUserDataTracking } from "../graphqlEdited/mutations"

const initialState = {
  referrer: null,
  status: "idle",
}

export const UpdateUserTracking = createAsyncThunk(
  "tracking/UpdateUserTracking",
  async ({ id, tracking }, { rejectWithValue }) => {
    let response = ""
    delete tracking.status
    try {
      let input = { id, tracking: JSON.stringify(tracking) }
      response = await API.graphql(
        graphqlOperation(updateUserDataTracking, { input })
      )
      return response
    } catch (err) {
      console.error("tracking/UpdateUserTracking", err)
      return rejectWithValue(err)
    }
  }
)

const trackingSlice = createSlice({
  name: "tracking",
  initialState,
  reducers: {
    setUserTracking(state, action) {
      if (!action.payload) {
        // User has no tracking yet
        state.status = "ready"
        return
      }

      let parsedPayload

      if (typeof action.payload === "string") {
        try {
          // Try parsing the string payload
          parsedPayload = JSON.parse(action.payload)
        } catch (error) {
          // If parsing fails, set state to ready and log error if needed
          console.error("Failed to parse payload:", error)
          state.status = "ready"
          return
        }
      } else if (typeof action.payload === "object") {
        // If it's already an object, use it directly
        parsedPayload = action.payload
      } else {
        // If payload is neither string nor object, set state to ready
        state.status = "ready"
        return
      }

      return {
        ...state,
        ...parsedPayload,
        status: "ready",
      }
    },
    setTracking(state, action) {},
  },
  extraReducers: {
    [UpdateUserTracking.fulfilled]: (state, action) => {
      const { tracking } = action.payload.data.updateUserData
      if (!tracking) return
      state = { ...JSON.parse(tracking) }
      state.status = "idle"
      return state
    },
    [UpdateUserTracking.rejected]: () => {},
    [UpdateUserTracking.pending]: state => {
      state.status = "loading"
    },
  },
})

export const { setTracking, setUserTracking } = trackingSlice.actions

export default trackingSlice.reducer

import { newUserState } from "../../slices/userSlice"
import { customAlphabet, nanoid } from "nanoid/non-secure"
import { fees } from "../../consts/consts"

export const BookingNewDefault = () => {
  const miniNanoid = customAlphabet("1234567890", 4)
  const id = nanoid()
  return {
    ...newUserState,
    id: id,
    hostId: "",
    owner: "",
    listingId: "",
    priceOption: "",
    priceOptionPremium: "",
    price: "",
    pricePremium: "",
    fees: {
      feeHost: fees.current.feeHost,
      feeGuest: fees.current.feeGuest,
    },
    duration: "",
    durationPremium: "",
    currency: "USD",
    adults: 0,
    children: 0,
    message: "",
    timezone: "",
    // departurePrimary: DepartureDateTime({ day: 6 }),
    // departurePrimary: moment()
    //   .add(1, "week")
    //   .hour(10)
    //   .minutes(0)
    //   .seconds(0)
    //   .day(6)
    //   .toDate(),
    departurePrimary: undefined,
    departurePrimaryPremium: undefined,
    // departureSecondary: DepartureDateTime({ day: 7 }),
    departureSecondary: undefined,
    departureSecondaryPremium: undefined,
    secretCode: "FUN" + miniNanoid(),
    stepsCompleted: 0,
  }
}

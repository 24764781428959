import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { API, graphqlOperation } from "aws-amplify"
import { createBooking } from "../graphqlEdited/mutations"
import { getBooking } from "../graphqlEdited/queries"

import {
  bookingBoardingIDVerify,
  submitPayment,
  updateBookingGuest,
  updateBookingHost,
} from "../graphqlEdited/mutations"
import {
  onCreateBooking,
  onCreateChat,
  onUpdateBooking,
} from "../graphql/subscriptions"

import { BookingNewDefault } from "../components/booking/BookingNewDefault"
import {
  bookingsWithStatus,
  bookingWithStatus,
} from "../components/booking/BookingStatus"

const initialState = {
  items: [],
  status: "idle",
  error: null,
  new: BookingNewDefault(),
  newBookingStatus: "idle",
  payment: null,
}

export const bookingHasNewMessages = ({ items, userId }) =>
  items.map(booking => ({
    ...booking,
    chatNewMessages: booking.chat.items.filter(
      item => item.to === userId && item.toSeen === null
    )?.length,
  }))

export const BookingUpdate = createAsyncThunk(
  "booking/BookingUpdate",
  async (data, { rejectWithValue }) => {
    try {
      let sub,
        reqData = { ...data }
      if (reqData.sub) {
        sub = reqData.sub
        delete reqData.sub
      }

      const response = await API.graphql(
        graphqlOperation(
          sub === reqData.owner ? updateBookingGuest : updateBookingHost,
          {
            input: { ...reqData },
          }
        )
      )
      if (sub) {
        response.sub = sub
      }
      return response
    } catch (err) {
      console.error(err)
      return rejectWithValue(err)
    }
  }
)

export const BookingCreateNew = createAsyncThunk(
  "booking/BookingCreateNew",
  async ({ data }, { rejectWithValue }) => {
    try {
      const input = { ...data, fees: JSON.stringify(data.fees) }
      return await API.graphql(
        graphqlOperation(createBooking, {
          input,
        })
      )
    } catch (err) {
      console.error("booking/BookingCreateNew", err)
      return rejectWithValue(err.response.data)
    }
  }
)

export const BookingPaymentToken = createAsyncThunk(
  "booking/BookingPaymentToken",
  async (
    { number, expiry, cvc, bookingId, stripeConnectPK, country, postal },
    { rejectWithValue }
  ) => {
    try {
      const expirySplit = expiry.split("/")
      const card = {
        "card[number]": number.replace(/ /g, ""), //remove white spaces
        "card[exp_month]": Number(expirySplit[0]),
        "card[exp_year]": Number(expirySplit[1]),
        "card[cvc]": cvc,
        "card[address_zip]": postal, // add postal code here
        // "card[country]": country,
      }

      const options = {
        headers: {
          // Use the correct MIME type for your server
          Accept: "application/json",
          // Use the correct Content Type to send data to Stripe
          "Content-Type": "application/x-www-form-urlencoded",
          // Use the Stripe publishable key as Bearer
          Authorization: `Bearer ${
            // TURN OFF STRIPE CONNECT
            // stripeConnectPK || process.env.GATSBY_STRIPE_PUBLIC
            process.env.GATSBY_STRIPE_PUBLIC
          }`,
        },
        // Use a proper HTTP method
        method: "post",
        // Format the credit card data to a string of key-value pairs
        // divided by &
        body: Object.keys(card)
          .map(key => key + "=" + card[key])
          .join("&"),
      }

      const res = await fetch("https://api.stripe.com/v1/tokens", options)

      const resObj = await res.json()

      resObj.bookingId = bookingId

      console.log(resObj)

      return resObj
    } catch (err) {
      console.error("booking/BookingPaymentToken", err)
      return rejectWithValue(err)
    }
  }
)
export const BookingPayment = createAsyncThunk(
  "booking/BookingPayment",
  async ({ tokenId, bookingId }, { rejectWithValue }) => {
    try {
      const paymentEnv = window?.origin?.includes("localhost")
        ? "dev_test_123"
        : "prod"

      const response = await API.graphql(
        graphqlOperation(submitPayment, {
          paymentTokenId: tokenId,
          paymentEnv,
          input: {
            id: bookingId,
            paymentCreateAt: new Date().toISOString(),
          },
        })
      )

      return response
    } catch (err) {
      console.error("booking/BookingPayment", err)
      return rejectWithValue(err)
    }
  }
)

export const updateChats = createAsyncThunk(
  "booking/updateChats",
  async ({ to, from, onUpdate }, { rejectWithValue }) => {
    let subscription
    try {
      subscription = await API.graphql(
        graphqlOperation(onCreateChat, {
          to,
          from,
        })
      ).subscribe({
        next: ({ value }) => {
          onUpdate({ ...value.data.onCreateChat })
        },
        error: error => {
          console.error(JSON.stringify(error))
        },
      })
      //keep it like that so can return the cleanup function
      return subscription
      // return JSON.parse(JSON.stringify(subscription))
    } catch (err) {
      console.error(JSON.stringify(err))
      return rejectWithValue(err)
    }
  }
)

export const onUpdateBookings = createAsyncThunk(
  "booking/onUpdateBooking",
  async (data, { rejectWithValue }) => {
    let subscription
    subscription = await API.graphql(
      graphqlOperation(onUpdateBooking, {
        owner: data.owner,
      })
    ).subscribe({
      next: ({ provider, value }) => {
        console.log({ provider, value })
        if (data?.onUpdate) {
          data.onUpdate(value.data.onUpdateBooking)
        } else {
        }
      },
      error: error => console.warn(error),
    })
    return subscription
  }
)

export const BookingBoardingIDVerify = createAsyncThunk(
  "booking/BookingBoardingIDVerify",
  async (data, { rejectWithValue }) => {
    //console.log('DATA', data);
    try {
      const response = await API.graphql(
        graphqlOperation(bookingBoardingIDVerify, {
          input: {
            id: data.id,
            secretCodeSubmitAt: data.secretCodeSubmitAt,
          },
          secretCode: data.secretCode,
        })
      )
      return response
    } catch (err) {
      console.error("booking/BookingBoardingIDVerify", err)
      return rejectWithValue(err)
    }
  }
)

export const BookingRefresh = createAsyncThunk(
  "booking/BookingRefresh",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const { id, source } = data
      const response = await API.graphql(
        graphqlOperation(getBooking, {
          id,
        })
      )
      return response
    } catch (err) {
      console.error(err)
      return rejectWithValue(err)
    }
  }
)

export const BookingCreateSubscription = createAsyncThunk(
  "booking/onCreateBooking",
  async (data, { rejectWithValue, dispatch }) => {
    const subscription = await API.graphql(
      graphqlOperation(onCreateBooking, {
        hostId: data.to,
      })
    ).subscribe({
      next: ({ provider, value }) => {
        //console.log({provider, value});
        if (value?.data?.onCreateBooking) {
          dispatch(
            BookingRefresh({
              id: value.data.onCreateBooking.id,
              source: "BookingCreateSubscription",
            })
          )
          // dispatch(UpdateCounter({ increment: 1 }))
        }
      },
      error: error => {
        console.warn(error)
      },
    })
    return subscription
  }
)

const bookingSlice = createSlice({
  name: "booking",
  initialState,
  reducers: {
    BookingNewStart: (state, action) => {
      state.newBookingStatus = "in-progress"
    },
    BookingNewReset: (state, action) => {
      state.newBookingStatus = "idle"
    },
    BookingStepSubmit: (state, action) => {
      state.new = {
        ...state.new,
        ...action.payload,
      }
    },
    BookingHasNewMessages: (state, action) => {
      if (!action.payload.id) return

      state.items = bookingHasNewMessages({
        items: state.items,
        userId: action.payload.id,
      })
    },
    BookingResetNew: (state, action) => {
      const bookingNew = BookingNewDefault()
      state.new = { ...bookingNew }
      state.newBookingStatus = "idle"
    },
    BookingResetStatus: state => {
      state.status = "idle"
    },
    BookingStatusUpdate: (state, action) => {
      state.status = action.payload
    },
    BookingSubmitStart: state => {
      state.status = "loading"
    },
    BookingReset: state => {
      state = { ...initialState, status: "idle" }
      return state
    },
    BookingAddStatuses: (state, action) => {
      if (!action.payload.items) return
      state.items = bookingsWithStatus(
        action.payload.items,
        action.payload.userId
      )
    },
    BookingUserAdd: (state, action) => {
      const userData = action.payload.data.getUserData
      if (!userData) return
      const guestBookings = userData.guestBookings.items
      const hostBookings = userData.listings.items.reduce((sum, item) => {
        return sum.concat(item.bookings.items)
      }, [])
      state.items = [...guestBookings, ...hostBookings]
      state.status = "idle"
    },
    BookingChatCreate(state, action) {
      state.items = state.items.map(item => {
        if (item.id === action.payload.id) {
          return {
            ...item,
            chat: {
              ...item.chat,
              items: item.chat?.items?.concat(action.payload),
            },
            toSeen: null,
          }
        }
        return item
      })
    },
    BookingChatUpdate(state, action) {
      let bookingIndex = 0
      let chatIndex = 0
      state.items.forEach((item, i) => {
        if (item.id === action.payload.id) {
          bookingIndex = i
        }
      })
      state.items[bookingIndex].chat.items.forEach((item, i) => {
        if (item.createdAt === action.payload.createdAt) {
          chatIndex = i
        }
      })
      state.items[bookingIndex].chat.items[chatIndex] = {
        ...state.items[bookingIndex].chat.items[chatIndex],
        ...action.payload,
      }
    },
  },
  extraReducers: {
    [BookingBoardingIDVerify.rejected]: (state, action) => {
      state.status = "failed"
    },
    [BookingBoardingIDVerify.pending]: (state, action) => {
      state.status = "loading"
    },
    [BookingBoardingIDVerify.fulfilled]: (state, action) => {
      const updatedBooking = action.payload.data.updateBooking
      state.items = state.items.map(item => {
        if (item.id === updatedBooking.id) {
          return {
            ...item,
            ...updatedBooking,
          }
        }
        return item
      })
      state.status = "succeeded"
    },
    [BookingCreateNew.fulfilled]: (state, action) => {
      const newBooking = action.payload.data.createBooking
      if (!state.items.some(item => item.id === newBooking.id)) {
        state.items = state.items.concat(action.payload.data.createBooking)
      }
    },
    [BookingCreateNew.rejected]: (state, action) => {
      state.status = "BOOKING_CREATE_REJECTED"
    },
    [BookingCreateNew.pending]: (state, action) => {
      state.status = "BOOKING_CREATE_LOADING"
    },
    [BookingUpdate.fulfilled]: (state, action) => {
      const updatedBooking = action.payload.data.updateBooking
      state.items = state.items.map(item => {
        if (item.id === updatedBooking.id) {
          return {
            ...item,
            ...updatedBooking,
          }
        }
        return item
      })
      state.status = "succeeded"
    },
    [BookingUpdate.rejected]: (state, action) => {
      state.status = "rejected"
    },
    [BookingUpdate.pending]: (state, action) => {
      state.status = "loading"
    },
    [BookingPayment.fulfilled]: (state, action) => {
      const updatedBooking = action.payload.data.updateBooking
      const bookingId = action.meta.arg.bookingId
      state.items = state.items.map(item => {
        if (item.id === bookingId) {
          return {
            ...item,
            ...updatedBooking,
          }
        }
        return item
      })
      // if (updatedBooking.paymentCreate && updatedBooking.paymentCreateAt) {
      //removed the paymentCreateAt for now. is it needed?
      if (updatedBooking.paymentCreate) {
        state.status = "BOOKING_PAYMENT_SUCCESS"
      } else {
        state.status = "BOOKING_PAYMENT_FAILED"
      }
    },
    [BookingPayment.rejected]: (state, action) => {
      state.status = "BOOKING_PAYMENT_FAILED"
    },
    [BookingPayment.pending]: (state, action) => {
      state.status = "BOOKING_PAYMENT_LOADING"
    },
    [BookingPaymentToken.fulfilled]: (state, action) => {
      state.payment = action.payload
    },
    [BookingPaymentToken.rejected]: (state, action) => {
      state.status = "BOOKING_PAYMENT_FAILED"
    },
    [BookingPaymentToken.pending]: (state, action) => {
      state.status = "BOOKING_PAYMENT_LOADING"
    },
    [updateChats.rejected]: (state, action) => {},
    [updateChats.pending]: (state, action) => {},
    [updateChats.fulfilled]: (state, action) => {},
    [BookingRefresh.rejected]: (state, action) => {},
    [BookingRefresh.pending]: (state, action) => {},
    [BookingRefresh.fulfilled]: (state, action) => {
      const { id } = action.meta.arg
      const bookingExists = state.items.some(item => item.id === id)
      if (bookingExists) {
        // If booking exists, update it
        state.items = state.items.map(item => {
          if (item.id === id) {
            return {
              ...item,
              ...bookingWithStatus(action.payload.data.getBooking),
            }
          }
          return item
        })
      } else {
        // If booking doesn't exist, add it
        state.items.push(bookingWithStatus(action.payload.data.getBooking))
      }
    },
  },
})

export const {
  BookingStepSubmit,
  BookingNewStart,
  BookingNewReset,
  BookingResetNew,
  BookingResetStatus,
  BookingHasNewMessages,
  BookingSubmitStart,
  BookingAddStatuses,
  BookingStatusUpdate,
  BookingUserAdd,
  BookingReset,
  BookingChatCreate,
  BookingChatUpdate,
  addBooking,
} = bookingSlice.actions

export default bookingSlice.reducer

// import {FETCH_BOOKS_SUCCESS, FETCH_BOOKS_ERROR, GET_BOOKS, SELECT_BOOK, updateBooks} from "../actions/books";
// import {showSpinner} from "../actions/ui";
// import {apiRequest} from "../actions/api";
// import {hideSpinner} from "../actions/ui";
// import {orderInProgress} from "../actions/ui";
// import {createOrder} from "../actions/order";
//
// const URL = 'https://www.googleapis.com/books/v1/volumes?q=react';
import {
  UserChatMessageCreate,
  UserUpdate,
  loginUser,
  UserSignup,
  UserSignupWithOauth,
} from "../slices/userSlice"
import {
  BookingStatusUpdate,
  BookingCreateNew,
  BookingChatCreate,
  BookingResetNew,
  BookingUserAdd,
  BookingAddStatuses,
  BookingPayment,
  bookingHasNewMessages,
  BookingHasNewMessages,
} from "../slices/bookingSlice"
import { deviceUtcOffsets } from "../common/DateTime"
import { setToast } from "../slices/toastSlice"
import { navigate } from "gatsby"
import { InboxAddItems } from "../slices/inboxSlice"
import { bookingsWithStatus } from "../components/booking/BookingStatus"
import { SendEvents } from "../components/analytics/analyticsEvents"
import { store } from "../app/store"

export const BookingUpdateMdl = ({ dispatch, getState }) => next => action => {
  next(action)
  if (action.type === "booking/BookingUpdate/fulfilled") {
    if (
      !action.meta.arg.seenHost &&
      !action.meta.arg.seenGuest &&
      !action.meta.arg.verifySubmit
    ) {
      dispatch(
        setToast({
          message: "Booking updated successfully",
          type: "SUCCESS",
        })
      )
    }
    const { booking, user } = getState()
    dispatch(BookingAddStatuses({ items: booking.items, userId: user.data.id }))
  }
  if (action.type === "booking/BookingUpdate/rejected") {
    dispatch(
      setToast({
        message: "Oops! Something went wrong!",
        type: "FAILED",
      })
    )
  }
}

export const BookingItemsMdl = ({ dispatch, getState }) => next => action => {
  next(action)
  if (action.type === "user/getUserData/fulfilled") {
    dispatch(BookingUserAdd(action.payload))
  }
  if (action.type === "booking/BookingUserAdd") {
    const { user, booking } = getState()
    dispatch(BookingAddStatuses({ items: booking.items, userId: user.data.id }))
    dispatch(BookingHasNewMessages({ id: user.data.id }))
  }
}

export const BookingSignupMdl = ({ dispatch, getState }) => next => action => {
  next(action)

  const rejected = "user/verify/rejected"
  if (action.type === rejected) {
    dispatch(
      setToast({
        message: "Oops! Something went wrong!",
        type: "FAILED",
      })
    )
    if (typeof window !== "undefined" && typeof window?.gtag === "function") {
      window.gtag("event", rejected, { ...action })
    }
  }

  const UserSignupfulfilled = "user/UserSignup/fulfilled"
  if (action.type === UserSignupfulfilled) {
    if (action.meta.arg.bookingType === "SIGNUP_WITH_EMAIL") {
      dispatch(BookingStatusUpdate("BOOKING_CODE_VERIFY_START"))
    }
    if (typeof window !== "undefined" && typeof window?.gtag === "function") {
      window.gtag("event", UserSignupfulfilled, {
        bookingType: action.meta.arg.bookingType,
      })
    }
  }

  const UserSignuprejected = "user/UserSignup/rejected"
  if (action.type === UserSignuprejected) {
    if (action.meta.arg.bookingType === "SIGNUP_WITH_EMAIL") {
      dispatch(BookingStatusUpdate("idle"))
    }
    const text = action?.payload?.code?.includes("Exception")
      ? action.payload.message
      : "Oops! Something went wrong!"
    dispatch(setToast({ message: text, type: "FAILED" }))
    if (typeof window !== "undefined" && typeof window?.gtag === "function") {
      window.gtag("event", UserSignuprejected, {
        bookingType: action.meta.arg.bookingType,
      })
    }
  }

  const loginUserFulfilled = "user/loginUser/fulfilled"
  const loginUserWithOauthFulfilled = "user/UserSignupWithOauth/fulfilled"
  if (
    action.type === loginUserFulfilled ||
    action.type === loginUserWithOauthFulfilled
  ) {
    if (
      action.meta.arg.bookingType === "SIGNUP_WITH_EMAIL" ||
      action.meta.arg.bookingType === "SIGNUP_WITH_OAUTH"
    ) {
      const { user, booking } = getState()
      const data = {
        owner: user.data.id,
        notify: "BookingSentRequest",
        id: booking.new.id,
        listingId: booking.new.listingId,
        hostId: booking.new.hostId,
        priceOption: booking.new.priceOption,
        price: booking.new.price,
        fees: booking.new.fees,
        duration: booking.new.duration,
        priceOptionPremium: booking.new.priceOptionPremium,
        pricePremium: booking.new.pricePremium,
        durationPremium: booking.new.durationPremium,
        currency: booking.new.currency,
        adults: booking.new.adults,
        children: booking.new.children,
        message: booking.new.message,
        departurePrimary: booking.new.departurePrimary,
        timezone: booking.new.timezone,
        departurePrimaryPremium: booking.new.departurePrimaryPremium,
        departureSecondary: booking.new.departureSecondary,
        departureSecondaryPremium: booking.new.departureSecondaryPremium,
        secretCode: booking.new.secretCode,
      }
      dispatch(
        BookingCreateNew({ data, bookingType: action.meta.arg.bookingType })
      )
      SendEvents({
        eventName:
          action.type === loginUserFulfilled
            ? loginUserFulfilled
            : loginUserWithOauthFulfilled,
        data: { bookingType: action.meta.arg.bookingType },
      })
    }
  } else {
    //TODO: fetch near by listings here
  }

  if (action.type === "booking/BookingStepSubmit") {
    //final step to signup
    const {
      firstName,
      lastName,
      username,
      password,
      phone,
      step,
      bookingType,
    } = action.payload
    if (step === 4) {
      dispatch(BookingStatusUpdate("BOOKING_SUBMIT_DATA"))
      if (bookingType === "SIGNUP_WITH_EMAIL") {
        dispatch(
          UserSignup({
            firstName,
            lastName,
            username,
            password,
            phone,
            primaryType: "USER",
            newsletter: false,
            timezone: deviceUtcOffsets(),
            bookingType: "SIGNUP_WITH_EMAIL",
          })
        )
      } else if (bookingType === "SIGNUP_WITH_OAUTH") {
        dispatch(
          UserSignupWithOauth({
            firstName,
            lastName,
            phone,
            primaryType: "USER",
            newsletter: false,
            timezone: deviceUtcOffsets(),
            bookingType: "SIGNUP_WITH_OAUTH",
          })
        )
      } else {
        // const { user: bookingUser, booking: newBooking } = getState()
        const { user: bookingUser, booking: newBooking } = store.getState() // without assigning the values they were undefined
        const bookingData = {
          id: newBooking.new.id,
          owner: bookingUser.data.id,
          listingId: newBooking.new.listingId,
          notify: "BookingSentRequest",
          hostId: newBooking.new.hostId,
          priceOption: newBooking.new.priceOption,
          price: newBooking.new.price,
          fees: newBooking.new.fees,
          duration: newBooking.new.duration,
          priceOptionPremium: newBooking.new.priceOptionPremium,
          pricePremium: newBooking.new.pricePremium,
          durationPremium: newBooking.new.durationPremium,
          currency: newBooking.new.currency,
          adults: newBooking.new.adults,
          children: newBooking.new.children,
          message: newBooking.new.message,
          timezone: newBooking.new.timezone,
          departurePrimary: newBooking.new.departurePrimary,
          departurePrimaryPremium: newBooking.new.departurePrimaryPremium,
          departureSecondary: newBooking.new.departureSecondary,
          departureSecondaryPremium: newBooking.new.departureSecondaryPremium,
          secretCode: newBooking.new.secretCode,
        }
        dispatch(BookingCreateNew({ data: bookingData, bookingType: "USER" }))
        if (phone !== bookingUser.data.phone) {
          dispatch(
            UserUpdate({
              id: bookingUser.data.id,
              phone,
              context: "BOOKING",
            })
          )
        }
      }
    }
  }

  if (action.type === "user/verify/fulfilled") {
    dispatch(
      setToast({
        message: "Account verification success",
        type: "SUCCESS",
      })
    )
    if (typeof window !== "undefined" && typeof window?.gtag === "function") {
      window.gtag("event", "user/verify/fulfilled", { ...action })
    }
    if (action.meta.arg.signupType === "BOOKING") {
      const state = getState()
      dispatch(
        loginUser({
          username: state.user.email,
          password: state.user.password,
          bookingType: "SIGNUP_WITH_EMAIL",
        })
      )
    }
  }

  const BookingBoardingIDVerify = "booking/BookingBoardingIDVerify/fulfilled"
  if (action.type === BookingBoardingIDVerify) {
    const { booking, user } = getState()
    dispatch(BookingAddStatuses({ items: booking.items, userId: user.data.id }))
    dispatch(BookingStatusUpdate("idle"))
    if (action.payload.data.updateBooking.secretCodeSubmit) {
      dispatch(
        setToast({
          message: "Boarding ID is valid, the trip started.",
          type: "SUCCESS",
        })
      )
      if (typeof window !== "undefined" && typeof window?.gtag === "function") {
        window.gtag("event", BookingBoardingIDVerify + "-SUCCESS", {})
      }
    } else {
      dispatch(
        setToast({
          message: "Invalid Boarding ID submitted!",
          type: "FAILED",
        })
      )
      if (typeof window !== "undefined" && typeof window?.gtag === "function") {
        window.gtag("event", BookingBoardingIDVerify + "-FAILED", {})
      }
    }
  }

  const BookingBoardingIDVerifyRejected =
    "booking/BookingBoardingIDVerify/rejected"
  if (action.type === BookingBoardingIDVerifyRejected) {
    dispatch(BookingStatusUpdate("idle"))
    dispatch(
      setToast({
        message: "Oops! Something went wrong!",
        type: "FAILED",
      })
    )
    if (typeof window !== "undefined" && typeof window?.gtag === "function") {
      window.gtag("event", BookingBoardingIDVerifyRejected, { ...action })
    }
  }

  if (action.type === "booking/UserUpdate/fulfilled") {
    const { booking, user } = getState()
    dispatch(BookingAddStatuses({ items: booking.items, userId: user.data.id }))
    dispatch(BookingStatusUpdate("idle"))
    if (
      !action.meta.arg.seenHost &&
      !action.meta.arg.seenGuest &&
      !action.meta.arg.acceptedAtGuest &&
      !action.meta.arg.verifySubmit
    ) {
      dispatch(setToast({ message: "Booking updated", type: "SUCCESS" }))
    }
  }

  if (action.type === "booking/UserUpdate/rejected") {
    dispatch(BookingStatusUpdate("idle"))
    dispatch(
      setToast({ message: "Oops! Something went wrong!", type: "FAILED" })
    )
  }

  if (action.type === "booking/BookingCreateNew/fulfilled") {
    const { message, id, owner, hostId } = action.meta.arg.data
    const { user, booking } = getState()
    dispatch(BookingAddStatuses({ items: booking.items, userId: owner }))

    if (action.meta.arg.bookingType === "USER") {
      if (message) {
        dispatch(
          UserChatMessageCreate({
            id,
            from: owner,
            fromName: user.data.firstName,
            notify: "Skip",
            to: hostId,
            message: message,
          })
        )
      }
    }

    if (
      action.meta.arg.bookingType === "SIGNUP_WITH_EMAIL" ||
      action.meta.arg.bookingType === "SIGNUP_WITH_OAUTH"
    ) {
      if (message) {
        dispatch(
          UserChatMessageCreate({
            id,
            from: owner,
            fromName: user.data.firstName,
            notify: "NewMessage",
            to: hostId,
            message: message,
          })
        )
      }
      dispatch(
        BookingChatCreate({
          id,
          from: owner,
          fromName: user.data.firstName,
          to: hostId,
          message: message,
        })
      )
    }

    if (action.meta.arg.bookingType === "BOOST") {
      dispatch(
        setToast({
          message: `Boost request sent`,
          type: "SUCCESS",
        })
      )
    } else {
      navigate(`/booking/request-sent/${id}`, {
        state: { isNew: true },
      })
    }

    // dispatch(addNewGuestBooking(bookingData));

    dispatch(BookingStatusUpdate("BOOKING_CREATE_SUCCESS"))
    dispatch(BookingStatusUpdate("idle"))
    dispatch(BookingResetNew())
  }

  const bookingCreateNewRejected = "booking/BookingCreateNew/rejected"
  if (action.type === bookingCreateNewRejected) {
    dispatch(BookingStatusUpdate("BOOKING_CREATE_REJECTED"))
    dispatch(BookingStatusUpdate("idle"))
    dispatch(
      setToast({ message: "Oops! Something went wrong!", type: "FAILED" })
    )
    if (typeof window !== "undefined" && typeof window?.gtag === "function") {
      window.gtag("event", bookingCreateNewRejected, {})
    }
  }
}

export const BookingPaymentMdl = ({ dispatch, getState }) => next => action => {
  next(action)
  if (action.type === "booking/BookingPaymentToken/fulfilled") {
    const { id, bookingId } = action.payload
    const { amount } = action.meta.arg
    dispatch(BookingPayment({ tokenId: id, bookingId, amount }))
  }
  if (action.type === "booking/BookingPayment/fulfilled") {
    const { booking, user } = getState()
    dispatch(BookingAddStatuses({ items: booking.items, userId: user.data.id }))
    //if user is ID verified dispatch email
    // if (user.identity.govId && user.identity.selfie) {
    //   dispatch(
    //     BookingUpdate({
    //       sub: user.data.id,
    //       id: action.meta.arg.bookingId,
    //       owner: user.data.id,
    //       notify: "BookingConfirmed",
    //       seenGuest: new Date(), //without it was crashing
    //     })
    //   )
    //   dispatch(
    //     BookingUpdate({
    //       sub: user.data.id,
    //       id: action.meta.arg.bookingId,
    //       owner: user.data.id,
    //       notify: "BookingConfirmedHost",
    //       seenGuest: new Date(), //without it was crashing
    //     })
    //   )
    // }
  }
}

export const BookingReviewMdl = ({ dispatch }) => next => action => {
  next(action)
  const bookingReviewCreateFulfilled = "booking/BookingReviewCreate/fulfilled"
  if (action.type === bookingReviewCreateFulfilled) {
    dispatch(
      setToast({
        message: "Review submitted successfully",
        type: "SUCCESS",
      })
    )
    if (typeof window !== "undefined" && typeof window?.gtag === "function") {
      window.gtag("event", bookingReviewCreateFulfilled, {})
    }
  }
  const bookingReviewCreateRejected = "booking/BookingReviewCreate/rejected"
  if (action.type === bookingReviewCreateRejected) {
    dispatch(
      setToast({
        message: "Oops! Something went wrong!",
        type: "FAILED",
      })
    )
    if (typeof window !== "undefined" && typeof window?.gtag === "function") {
      window.gtag("event", bookingReviewCreateRejected, {})
    }
  }
}
export const BookingsToInbox = ({ dispatch, getState }) => next => action => {
  if (action?.type === "booking/BookingAddStatuses") {
    const { user } = getState()
    const itemsWithStatus = bookingsWithStatus(
      action.payload.items,
      user.data.id
    )
    const itemsWithMessageCount = bookingHasNewMessages({
      items: itemsWithStatus,
      userId: user.data.id,
    })
    dispatch(InboxAddItems(itemsWithMessageCount))
  }
  next(action)
}

export const BookingMdl = [
  BookingSignupMdl,
  BookingItemsMdl,
  BookingPaymentMdl,
  BookingReviewMdl,
  BookingUpdateMdl,
  BookingsToInbox,
]

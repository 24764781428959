import { PremiumCheck } from "./BookingPremiumCheck"
import { addHours } from "../../common/DateTime"

const GetBookingDuration = ({
  departureHost,
  duration,
  durationPremium,
}) => {
  //does departureGuest exist?
  try {
    return PremiumCheck(departureHost) ? durationPremium : duration
  } catch (err) {
    console.error(err)
  }
}


export const bookingWithStatus = (booking, userId) => {
  if (!booking.owner) {
    // console.log(booking);
  }
  const duration = GetBookingDuration(booking);
  if (booking.owner === userId) {
    if (booking.cancelAtHost) {
      return {...booking, status: 'CANCELED'};
    } else if (booking.declineAtHost || booking.declineAtGuest) {
      return {...booking, status: 'DECLINED'};
    } else if (
      (booking.acceptedAtGuest || booking.acceptedAtHost) &&
      !booking.paymentSuccess
    ) {
      return {...booking, status: 'PAY'};
    } else if (
      !booking.acceptedAtGuest &&
      !booking.acceptedAtHost &&
      !booking.departureHost
    ) {
      return {...booking, status: 'SENT'};
    } else if (
      !booking.seenGuest &&
      booking.departureHost !== booking.departurePrimary &&
      booking.departureHost !== booking.departureSecondary &&
      !booking.acceptedAtGuest
    ) {
      return {...booking, status: 'CHANGE'};
    } else if (
      (booking.acceptedAtGuest || booking.acceptedAtHost) &&
      booking.paymentSuccess &&
      !booking.verifySubmit
    ) {
      return {...booking, status: 'VERIFY'};
    } else if (
      (booking.paymentSuccess || booking.verifySubmit) &&
      !booking.secretCodeSuccess
    ) {
      return {...booking, status: 'BOOKED'};
    } else if (
      booking.secretCodeSuccess &&
      new Date() > new Date(booking.secretCodeSuccess) &&
      new Date() < addHours({hours: duration, date: new Date(booking.secretCodeSuccess)})
    ) {
      return {...booking, status: 'CURRENT'};
    } else if (
      !booking.cancelAtHost &&
      booking.secretCodeSuccess &&
      new Date() > addHours({hours: duration, date: new Date(booking.secretCodeSuccess)})
    ) {
      return {...booking, status: 'COMPLETED'};
    }
  } else {
    //HOST
    if (
      !booking.cancelAtHost &&
      booking.secretCodeSuccess &&
      new Date() > addHours({hours: duration, date: new Date(booking.secretCodeSuccess)})
    ) {
      return {...booking, status: 'COMPLETED'};
    } else if (
      booking.paymentSuccess &&
      booking.verifySubmit &&
      !booking.secretCodeSubmit
    ) {
      return {...booking, status: 'BOOKED'};
    } else if (booking.cancelAtHost) {
      return {...booking, status: 'CANCELED'};
    } else if (booking.declineAtHost || booking.declineAtGuest) {
      return {...booking, status: 'DECLINED'};
    } else if (
      !booking.seenHost &&
      !booking.acceptedAtGuest &&
      !booking.acceptedAtHost &&
      !booking.departureHost &&
      !booking.accepted
    ) {
      return {...booking, status: 'NEW'};
    } else if (
      booking.seenHost &&
      !booking.acceptedAtGuest &&
      !booking.acceptedAtHost &&
      !booking.departureHost &&
      !booking.accepted
    ) {
      return {...booking, status: 'REQUEST'};
    } else if (
      ((booking.acceptedAtGuest ||
        booking.acceptedAtHost ||
        booking.departureHost) &&
        !booking.paymentSuccess) ||
      !booking.verifySubmit
    ) {
      return {...booking, status: 'ACCEPTED'};
    } else if (
      booking.secretCodeSubmit && //lambda returns this value first
     new Date() < addHours({hours: duration})
    ) {
      return {...booking, status: 'CURRENT'};
    }
  }
};

export const bookingsWithStatus = (bookings, userId) => {
  try {
    return bookings.length > 0
      ? bookings.map(booking => {
        return bookingWithStatus(booking, userId);
      })
      : [];
  } catch (err) {
    console.error(err);
  }
};

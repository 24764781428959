import * as Sentry from "@sentry/gatsby"

Sentry.init({
  dsn:
    "https://15c0e9c0bf2340d9ab4e1545a4c453b1@o1202749.ingest.sentry.io/4505166176387072",
  integrations: [new Sentry.Replay()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  enabled:
    typeof window !== "undefined" && window?.location?.hostname !== "localhost",
})

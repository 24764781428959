import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  duration: null, text: null, type: null,
}

const toastSlice = createSlice({
  name: "toast",
  initialState,
  reducers: {
    resetToast(state, action){
      state.text = null
      state.type =  null
      state.duration = null
    },
    setToast(state, action) {
      state.text = action.payload.message
      state.type =  action.payload.type
      state.duration = 8000
    },
  },
  extraReducers: {},
})

export const { setToast, resetToast } = toastSlice.actions

export default toastSlice.reducer

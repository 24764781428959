import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { API, graphqlOperation, Auth, Storage } from "aws-amplify"
import { getUser } from "../graphqlEdited/queries"
import {
  createPhoto,
  createChat,
  updateChat,
  createContactUs,
} from "../graphql/mutations"
import { updateUserData, createUserData } from "../graphqlEdited/mutations"
import { nanoid } from "nanoid/non-secure"
// import { bookingsWithStatus } from "./bookingSlice"
import { setUserTracking } from "./trackingSlice"

export const newUserState = {
  firstName: "",
  lastName: "",
  username: "",
  primaryType: "",
  phone: "",
}

const identity = {
  selfie: undefined,
  govId: undefined,
}

const initialState = {
  data: null,
  createStatus: "idle",
  status: "idle",
  logoutConfirmShow: false,
  userLoginBefore: undefined,
  userUnauthed: undefined,
  userData: {
    status: "idle",
  },
  //sub will be set if session exists
  sub: undefined,
  email: undefined,
  email_verified: undefined,
  forgotPasswordDelivery: undefined,
  newPhoto: {
    status: "idle",
  },
  identity: identity,
  groups: [],
}

export const CreateContactUs = createAsyncThunk(
  "user/CreateContactUs",
  async ({ data, authed }, { rejectWithValue }) => {
    console.log("data", data)
    try {
      return await API.graphql({
        query: createContactUs,
        variables: {
          input: {
            ...data,
          },
        },
        authMode: "AWS_IAM",
      })
    } catch (err) {
      console.log(err)
      return rejectWithValue(err.response.data)
    }
  }
)

export const newUserPhotoThunk = createAsyncThunk(
  "user/newUserPhotoThunk",
  async (
    { userId, photoType, photoNumber, id, blob, type, enabled, level },
    { rejectWithValue }
  ) => {
    //console.log('props', props);
    try {
      const photoId = id + "--" + photoNumber,
        filename = photoId

      // const parsed = atob(blob)
      const blobData = await fetch(blob).then(res => res.blob())

      //TODO where does the file get upload? Finish test...

      await Storage.put("upload/" + filename, blobData, {
        level,
        contentType: type,
      })

      const input = {
        id: photoId,
        fileType: type,
        photoType: photoType,
        userId: userId,
        enabled: enabled,
      }

      return await API.graphql(
        graphqlOperation(createPhoto, {
          input,
        })
      )
    } catch (err) {
      console.error("user/newUserPhotoThunk", err)
      return rejectWithValue(err)
    }
  }
)

export const UserSignup = createAsyncThunk(
  "user/UserSignup",
  async (
    {
      firstName,
      lastName,
      username,
      password,
      primaryType,
      newsletter,
      timezone,
      phone,
    },
    { rejectWithValue }
  ) => {
    try {
      const response = JSON.parse(
        JSON.stringify(
          await Auth.signUp({
            username,
            password,
          })
        )
      )
      const response2 = await API.graphql({
        query: createUserData,
        variables: {
          input: {
            //match id with cognito create id
            id: response.userSub,
            firstName,
            lastName,
            primaryType,
            newsletter,
            timezone,
            email: username,
            phone,
          },
        },
        authMode: "AWS_IAM",
      })
      return { ...response, ...response2 }
    } catch (err) {
      console.error("user/UserSignup", err)
      return rejectWithValue(err)
    }
  }
)

export const UserSignupWithOauth = createAsyncThunk(
  "user/UserSignupWithOauth",
  async (
    { firstName, lastName, primaryType, newsletter, timezone, phone },
    { rejectWithValue }
  ) => {
    try {
      const response = JSON.parse(
        JSON.stringify(await Auth.currentAuthenticatedUser())
      )
      const response2 = await API.graphql({
        query: createUserData,
        variables: {
          input: {
            //match id with cognito create id
            id: response.username, //username is how cognito and graphql api link
            firstName,
            lastName,
            primaryType,
            newsletter,
            timezone,
            email: response.attributes.email,
            phone,
          },
        },
        authMode: "AWS_IAM",
      })
      return { ...response, ...response2 }
    } catch (err) {
      console.error("user/UserSignupWithOauth", err)
      return rejectWithValue(err)
    }
  }
)

export const loginUser = createAsyncThunk(
  "user/loginUser",
  async ({ username, password, remember }, { rejectWithValue, dispatch }) => {
    try {
      if (remember) {
        localStorage.setItem("USERNAME", username)
      } else {
        localStorage.removeItem("USERNAME")
      }

      const response = JSON.parse(
        JSON.stringify(await Auth.signIn(username.toLowerCase(), password))
      )
      //needed otherwise it says no user
      //await Auth.currentAuthenticatedUser()

      const response2 = await API.graphql(
        graphqlOperation(getUser, { id: response.username })
      )

      if (response2.data?.getUserData) {
        dispatch(setUserTracking(response2.data.getUserData.tracking))
        // this is needed to populate the user data in redux for sending inquiries
        // delete response2.data.getUserData
      }

      //set this so user is take to login vs signup if they are not authed
      localStorage.setItem("userLoginBefore", "true")
      return { ...response, ...response2 }
    } catch (err) {
      // console.error("user/loginUser", err)
      return rejectWithValue(err)
    }
  }
)

export const getUserData = createAsyncThunk(
  "user/getUserData",
  async ({ id, source }, { rejectWithValue, dispatch }) => {
    try {
      const res = await API.graphql(graphqlOperation(getUser, { id }))

      if (res.data.getUserData) {
        //return tracking even if undefined still
        //so the tracker can start its process with status ready

        dispatch(setUserTracking(res.data.getUserData.tracking))
        delete res.data.getUserData.tracking
      }
      return res
    } catch (err) {
      console.log("user/getUserData", err)
      return rejectWithValue(JSON.parse(JSON.stringify(err)))
    }
  }
)

export const UserUpdate = createAsyncThunk(
  "user/UserUpdate",
  async (data, { rejectWithValue }) => {
    let response = ""
    try {
      let user = JSON.parse(
        JSON.stringify(await Auth.currentAuthenticatedUser())
      )
      let reqData = { ...data }
      reqData.username &&
        (await Auth.updateUserAttributes(user, {
          email: reqData.username,
        }))

      delete reqData.username
      delete reqData.context
      response = await API.graphql(
        graphqlOperation(updateUserData, { input: reqData })
      )
    } catch (err) {
      console.error("user/UserUpdate", err)
      return rejectWithValue(err)
    }

    return response
  }
)

export const getCurrentAuthUser = createAsyncThunk(
  "user/getCurrentAuthUser",
  async (data, { rejectWithValue }) => {
    try {
      return JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser()))
    } catch (err) {
      console.log("getCurrentAuthUser", err)
      return rejectWithValue(err)
    }
  }
)

export const makeUnauthUser = createAsyncThunk(
  "user/makeUnauthUser",
  async (data, { rejectWithValue }) => {
    try {
      return JSON.parse(JSON.stringify(await Auth.currentCredentials()))
    } catch (err) {
      console.log("makeUnauthUser", err)
      return rejectWithValue(err)
    }
  }
)

export const signOutUser = createAsyncThunk(
  "user/signOutUser",
  async (callback, { rejectWithValue }) => {
    try {
      const response = await Auth.signOut()
      localStorage?.removeItem("persist:root")
      callback && callback()
      return response
    } catch (err) {
      console.error("user/signOutUser", err)
      return rejectWithValue(err)
    }
  }
)

export const verify = createAsyncThunk(
  "user/verify",
  async ({ username, authCode }, { rejectWithValue }) => {
    try {
      return JSON.parse(
        JSON.stringify(await Auth.confirmSignUp(username, authCode))
      )
    } catch (err) {
      console.error("verify", err)
      return rejectWithValue(err)
    }
  }
)

export const newPhoto = createAsyncThunk("user/newPhoto", async props => {
  const photoId = nanoid(),
    filename = photoId + "." + props.fileType

  try {
    //const response = await fetch(pathToImageFile)
    //const blob = await response.blob()

    await Storage.put(filename, "Protected Content", {
      level: "protected",
      contentType: "text/plain",
    })

    return await API.graphql(
      graphqlOperation(createPhoto, {
        input: {
          id: photoId,
          userId: props.userId,
          fileType: props.fileType,
          photoType: props.photoType,
        },
      })
    )
  } catch (err) {
    console.log(err)
  }
})

export const UserChatMessageCreate = createAsyncThunk(
  "user/UserChatMessageCreate",
  async (data, { rejectWithValue }) => {
    //console.log('DATA', data);
    try {
      return await API.graphql(
        graphqlOperation(createChat, {
          input: { ...data },
        })
      )
    } catch (err) {
      console.error("user/UserChatMessageCreate", err)
      return rejectWithValue(err)
    }
  }
)

export const updateChatMessage = createAsyncThunk(
  "user/updateChat",
  async (data, { rejectWithValue }) => {
    //console.log('DATA', data);
    try {
      return await API.graphql(
        graphqlOperation(updateChat, {
          input: { ...data },
        })
      )
    } catch (err) {
      console.log(err)
      return rejectWithValue(err)
    }
  }
)

export const UserLoginBefore = createAsyncThunk(
  "user/UserLoginBefore",
  async () => {
    return window.localStorage.getItem("userLoginBefore")
  }
)

export const UserResendSignupCode = createAsyncThunk(
  "user/UserResendSignupCode",
  async ({ username }) => {
    return JSON.parse(
      JSON.stringify(await Auth.resendSignUp(username.toLowerCase()))
    )
  }
)

export const forgotPassword = createAsyncThunk(
  "user/forgotPassword",
  async ({ username }, {}) => {
    return JSON.parse(
      JSON.stringify(await Auth.forgotPassword(username.toLowerCase()))
    )
  }
)

export const forgotPasswordSubmit = createAsyncThunk(
  "user/forgotPasswordSubmit",
  async ({ username, code, new_password }, { rejectWithValue }) => {
    try {
      return await Auth.forgotPasswordSubmit(
        username.toLowerCase(),
        code,
        new_password
      )
    } catch (err) {
      console.log(err)
      return rejectWithValue(err)
    }
  }
)

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    UserIdentityCheck: state => {
      state.identity.govId = state.data.photos?.items?.find(
        photo => photo.photoType === "GOVID"
      )
      state.identity.selfie = state.data.photos?.items?.find(
        photo => photo.photoType === "SELFIE"
      )
    },
    resetStatus: state => {
      state.status = "idle"
    },
    // toggleLogoutConfirm: state => {
    //   state.logoutConfirmShow = !state.logoutConfirmShow
    // },
    addNewUserListing: (state, payload) => {
      //console.log('state, payload', state, payload);
      state.data.listings.items.push(payload.payload.data.createListing)
    },
    UpdateUserListing: (state, action) => {
      const { payload } = action
      state.data.listings.items = state.data.listings.items.map(item => {
        if (item.id === payload.id) {
          return {
            ...item,
            ...payload,
          }
        }
        return item
      })
    },

    addNewUserListingPhoto: (state, payload) => {
      try {
        const photo = payload.data?.createPhoto
        state.data.listings.items = state.data.listings.items.map(listing =>
          listing.id === photo.listingId
            ? listing.photos.items.push({
                ...photo,
                local: payload.payload.local,
              })
            : listing
        )
      } catch (err) {
        console.log(err)
      }
    },
    // UserListingPhotosReplace: (state, action) => {
    //   const listing = state.data.listings.items.find(
    //     item => item.id === action.payload.id
    //   )
    //   if (listing) {
    //     listing.photos.items = action.payload.photos
    //   }
    // },
    submitStart: state => {
      state.status = "loading"
    },
    mockUserExistsForTest: state => {
      state.data = {
        id: "mock",
      }
      state.userUnauthed = false
    },
  },
  extraReducers: {
    [CreateContactUs.pending]: state => {
      state.status = "loading"
    },
    [CreateContactUs.rejected]: state => {
      state.status = "CONTACT_US_ERROR"
    },
    [CreateContactUs.fulfilled]: state => {
      state.status = "CONTACT_US_SUCCESS"
    },
    [getCurrentAuthUser.fulfilled]: (state, action) => {
      //console.log('currentUser', action);
      return {
        ...state,
        userUnauthed: false,
        //currentUserAuth: action.payload,
        sub: action.payload.attributes.sub,
        email: action.payload.attributes.email,
        email_verified: action.payload.attributes.email_verified,
        access_token: action.payload.signInUserSession.accessToken.jwtToken,
        groups:
          action.payload.signInUserSession?.idToken?.payload["cognito:groups"],
        identities: action.payload.attributes.identities,
      }
    },
    [getCurrentAuthUser.rejected]: state => {
      state.userUnauthed = true
    },
    [makeUnauthUser.fulfilled]: (state, action) => {
      //console.log('makeUnauthUser', action);
      state.sub = action.payload.identityId
      return state
      // return {
      //   ...state,
      //   //currentUserAuth: action.payload,
      //   sub: action.payload.attributes.sub,
      //   email: action.payload.attributes.email,
      //   email_verified: action.payload.attributes.email_verified,
      //   //   currentUserGroups:
      //   //     action.payload.signInUserSession.accessToken.payload[
      //   //       'cognito:groups'
      //   //     ],
      // };
    },
    [makeUnauthUser.rejected]: state => {
      // state.userUnauthed = true;
      return state
    },
    [newUserPhotoThunk.fulfilled]: (state, action) => {
      state.data.photos.items = state.data.photos.items.concat(
        action.payload.data.createPhoto
      )
      state.status = "succeeded"
    },
    [newUserPhotoThunk.rejected]: state => {
      state.status = "failed"
    },
    [newUserPhotoThunk.pending]: state => {
      state.status = "loading"
    },
    [UserSignup.fulfilled]: (state, action) => {
      state.data = action.payload.data.createUserData
      state.signupData = action.payload.user
      state.signupData.verified = false
      state.email = action.payload.user.username
      state.password = action.meta.arg.password
      state.sub = action.payload.userSub
      state.status = "succeeded"
    },
    [UserSignup.rejected]: (state, action) => {
      state.status = action.payload
    },
    [UserSignup.pending]: state => {
      state.status = "loading"
    },

    [UserSignupWithOauth.fulfilled]: (state, action) => {
      state.data = action.payload.data.createUserData
      state.signupData = {}
      state.signupData.verified = true
      state.sub = action.payload.attributes.sub
      state.status = "succeeded"
      state.oauthUser = action.payload.username
    },
    [UserSignupWithOauth.rejected]: (state, action) => {
      state.status = action.payload
    },
    [UserSignupWithOauth.pending]: state => {
      state.status = "loading"
    },

    [UserLoginBefore.fulfilled]: (state, action) => {
      state.userLoginBefore = action.payload
    },
    [UserLoginBefore.rejected]: () => {},
    [UserLoginBefore.pending]: () => {},
    [loginUser.fulfilled]: (state, action) => {
      return {
        ...state,
        data: action.payload.data.getUserData,
        status: "succeeded",
        userLoginBefore: true,
        userUnauthed: false,
        sub: action.payload.attributes.sub,
        email: action.payload.attributes.email,
        email_verified: action.payload.attributes.email_verified,
        identities: action.payload.attributes.identities,
      }
    },
    [loginUser.rejected]: (state, action) => {
      state.status = JSON.parse(JSON.stringify(action.payload))
    },
    [loginUser.pending]: state => {
      state.status = "loading"
    },
    [UserChatMessageCreate.fulfilled]: state => {
      state.status = "idle"
    },
    [UserChatMessageCreate.rejected]: state => {
      state.status = "failed"
    },
    [UserChatMessageCreate.pending]: state => {
      state.status = "loading"
    },
    [UserUpdate.fulfilled]: (state, action) => {
      return {
        ...state,
        data: { ...state.data, ...action.payload.data.updateUserData },
        status: "succeeded",
      }
    },
    [UserUpdate.rejected]: state => {
      return { ...state, status: "failed" }
    },
    [UserUpdate.pending]: state => {
      state.status = "loading"
    },
    [getUserData.fulfilled]: (state, action) => {
      //being used to fetch latest data for user during profile edit
      const getUserResponse = action.payload.data.getUserData
      state.data = {
        ...getUserResponse,
      }
      if (!state?.userData) {
        state.userData = {}
      }
      state.userData.status = "USER_DATA_FULFILLED"
    },
    [getUserData.rejected]: (state, action) => {
      //console.log('getUserData.rejected', action);
      state.status = action.error
    },
    [getUserData.pending]: state => {
      return state
    },
    [newPhoto.pending]: state => {
      state.newPhoto.status = "loading"
    },
    [newPhoto.fulfilled]: (state, action) => {
      state.data.photos.items.push(action.payload.data.createPhoto)
      state.newPhoto.status = "succeeded"
    },
    [newPhoto.rejected]: state => {
      state.newPhoto.status = "failed"
    },
    [verify.fulfilled]: state => {
      return {
        ...state,
        status: "succeeded",
        signupData: { ...state.signupData, verified: true },
      }
    },
    [verify.rejected]: (state, action) => {
      state.status = action.error
    },
    [verify.pending]: state => {
      state.status = "loading"
    },
    [signOutUser.fulfilled]: () => {
      return {
        ...initialState,
      }
    },
    [signOutUser.rejected]: (state, action) => {
      state.status = action.error
    },
    [signOutUser.pending]: state => {
      state.status = "loading"
    },
    [UserResendSignupCode.fulfilled]: () => {
      // \state.status = 'succeeded';
    },
    [UserResendSignupCode.rejected]: (state, action) => {
      state.status = action.error
    },
    [UserResendSignupCode.pending]: state => {
      state.status = "loading"
    },
    [forgotPassword.fulfilled]: (state, action) => {
      state.forgotPasswordDelivery = action.payload.CodeDeliveryDetails
      state.forgotPasswordDelivery.username = action.meta.arg.username
      state.status = "succeededCode"
    },
    [forgotPassword.rejected]: (state, action) => {
      state.status = action.error
    },
    [forgotPassword.pending]: state => {
      state.status = "loading"
    },
    [forgotPasswordSubmit.fulfilled]: state => {
      state.status = "succeededUpdate"
      state.forgotPasswordDelivery = undefined
    },
    [forgotPasswordSubmit.rejected]: (state, action) => {
      state.status = action.error
    },
    [forgotPasswordSubmit.pending]: state => {
      state.status = "loading"
    },
  },
})

export const {
  resetStatus,
  // toggleLogoutConfirm,
  // addNewUserListing,
  // UpdateUserListing,
  // addNewUserListingPhoto,
  // submitStart,
  UserIdentityCheck,
  // UserListingPhotosReplace,
  //addNewGuestBooking,
  mockUserExistsForTest,
} = userSlice.actions

export default userSlice.reducer

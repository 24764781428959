export const fees = {
  //changes to guest require some fixes to lambda
  original: {
    feeHost: 3,
    feeGuest: 15
  },
  current: {
    feeHost: 8,
    feeGuest: 15
  }
}

import { configureStore } from "@reduxjs/toolkit"
import { combineReducers } from "redux"

//PERSIST
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist"
import storage from "redux-persist/lib/storage"

//SLICES
import bookingSlice from "../slices/bookingSlice"
import listingsReducer from "../slices/listingsSlice"
import userSlice from "../slices/userSlice"
import locationReducer from "../slices/locationSlice"
import toastReducer from "../slices/toastSlice"
import inboxSlice from "../slices/inboxSlice"
import searchSlice from "../slices/searchSlice"
import trackingSlice from "../slices/trackingSlice"

//MIDDLEWARE

import { BookingMdl } from "../middleware/BookingMdl"
import { UserMdl } from "../middleware/UserMdl"
import { ListingsMdl } from "../middleware/ListingsMdl"
import { SearchMdl } from "../middleware/SearchMdl"
import { ChatMdl } from "../middleware/ChatMdl"
import { PersistMdl } from "../middleware/PersistMdl"
import { AnaltyicsMdl } from "../middleware/AnalyticsMdl"
// import { LoggerMdl } from "../middleware/LoggerMdl"
// import NavigationSlice from "../slices/NavigationSlice"
// import { navigationMdl } from "../middleware/NavigationMdl"

const persistConfig = {
  key: "root",
  version: 1,
  storage,
}

const rootReducer = combineReducers({
  listings: listingsReducer,
  booking: bookingSlice,
  user: userSlice,
  location: locationReducer,
  toast: toastReducer,
  inbox: inboxSlice,
  searching: searchSlice,
  tracking: trackingSlice,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const setupStore = () =>
  configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      })
        .concat(UserMdl)
        .concat(ChatMdl)
        .concat(ListingsMdl)
        .concat(SearchMdl)
        .concat(BookingMdl)
        .concat(PersistMdl)
        .concat(AnaltyicsMdl),
  })

export const store = setupStore()
export const persistor = persistStore(store)

// Optional: Define setupStore function if needed
// export function setupStore(preloadedState) {
//   return configureStore({
//     reducer: persistedReducer,
//     middleware: getDefaultMiddleware =>
//       getDefaultMiddleware({
//         serializableCheck: {
//           ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
//         },
//       })
//         .concat(UserMdl)
//         .concat(ChatMdl)
//         .concat(ListingsMdl)
//         .concat(SearchMdl)
//         .concat(BookingMdl)
//         .concat(PersistMdl)
//         .concat(AnaltyicsMdl),
//     preloadedState, // Optional: Initialize with preloaded state
//   })
// }

export const onlyDate = dateTime => {
  const date = new Date(dateTime)
  return date.toDateString()
}
export const onlyTime = dateTime => {
  const options = {
    hour12: true,
    hour: "numeric",
    minute: "numeric",
    // second: "numeric",
  }
  return new Date(dateTime).toLocaleTimeString(undefined, options)
}

export const LocalDate = dateTime => {
  const date = new Date(dateTime).toDateString().split(" ")
  return `${date[1]} ${date[2]}`
}

export const LocalDateWithYear = dateTime => {
  const date = new Date(dateTime).toDateString().split(" ")
  return `${date[1]} ${date[2]} ${date[3]}`
}

export const LocalDateFull = dateTime => {
  const date = new Date(dateTime)
  return `${date.toDateString()} at ${date.toLocaleTimeString()}`
}

export const TimeZoneDateFull = ({ dateTime, timezone }) => {
  const date = new Date(dateTime)
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    // hour12: false,
    timeZone: timezone,
    timeZoneName: "short",
  }
  const formatter = new Intl.DateTimeFormat("en-US", options)
  return formatter.format(date)
}

function pad(value) {
  return value < 10 ? "0" + value : value
}
export function createOffset(date) {
  const sign = date.getTimezoneOffset() > 0 ? "-" : "+"
  const offset = Math.abs(date.getTimezoneOffset())
  const hours = pad(Math.floor(offset / 60))
  const minutes = pad(offset % 60)
  return sign + hours + ":" + minutes
}

export const deviceUtcOffsets = () => {
  const d = new Date()
  return "UTC" + createOffset(d)
}

export const DepartureDateTime = ({ day, date }) => {
  try {
    const today = date || new Date()
    const nextWeek = new Date(today)
    nextWeek.setDate(today.getDate() + day + 1)
    const toJson = nextWeek.toJSON()
    const offsetToday = createOffset(today)
    return toJson.split("T")[0] + "T10:00:00" + offsetToday
  } catch (error) {
    console.error("DepartureDateTime error:", error)
  }
}

export const DepartureDateTimeFormat = ({ date, time = "10:00:00" }) => {
  return date.toJSON().split("T")[0] + "T" + time + createOffset(date)
}

export const subtractDays = days => {
  const d = new Date()
  const date = d.setDate(d.getDate() - days)
  return new Date(date)
}

export const addHours = ({ date = new Date(), hours }) => {
  date.setHours(date.getHours() + hours)
  return date
}

// export const formatDateTimeLocalWithZone = () => {
//   return new Date(h.toString().split('GMT')[0]+' UTC').toISOString().replace(".000Z","")+createOffset(h)
// }

import { PremiumCheck } from "../components/booking/BookingPremiumCheck"
import {
  SendEvents,
  SendEventsFBEvent,
} from "../components/analytics/analyticsEvents"

export const AnalyticsBookingSignupMdl = ({
  dispatch,
  getState,
}) => next => action => {
  next(action)
  if (action.type === "booking/BookingStepSubmit") {
    const {
      departurePrimary,
      departureSecondary,
      price,
      duration,
      pricePremium,
      durationPremium,
      adults,
      children,
      firstName,
      bookingType,
    } = action.payload
    window.dataLayer = window.dataLayer || []
    if (typeof window !== "undefined") {
    }
  }
}

export const AnalyticsBookingPaymentSuccessMdl = ({
  dispatch,
  getState,
}) => next => action => {
  next(action)
  if (
    action.type === "booking/BookingPayment/fulfilled" &&
    action.payload.data?.updateBooking?.paymentCreateAt &&
    action.payload.data?.updateBooking?.paymentCreate
  ) {
    const booking = action.payload.data?.updateBooking

    if (!booking) return

    const data = {
      amount: action.meta.arg?.amount,
    }

    const eventName = "booking/payment/success"

    const params = {
      value: action.meta.arg?.amount,
      currency: booking.currency,
      content_type: "product",
      content_ids: booking.listingId,
    }

    // SendEventsFBEvent({
    //   type: "Purchase",
    //   params,
    // })

    SendEvents({ eventName, data })
  }
}

export const AnalyticsPartnerWithUsSubmit = ({
  dispatch,
  getState,
}) => next => action => {
  next(action)
  if (
    action.type === "user/CreateContactUs/fulfilled" &&
    action.meta.arg?.type === "PARTNERS"
  ) {
    SendEventsFBEvent({
      type: "Lead",
      params: {
        content_category: "HOSTS",
        content_name: "PARTNERS",
        contents: { id: action.meta.arg.data.id },
      },
    })
  }
}
export const AnalyticsBookingRequestSentAddToCart = ({
  dispatch,
  getState,
}) => next => action => {
  next(action)
  if (
    action.type === "user/verify/fulfilled" &&
    action.meta.arg.signupType === "BOOKING"
  ) {
    const { booking } = getState()
    const params = {
      // value: PremiumCheck(booking.new.departurePrimary)
      //   ? booking.new.pricePremium
      //   : booking.new.price,
      value: 65.0,
      currency: "CAD",
      // currency: booking.new.currency,
      // content_type: "product",
      // content_ids: booking.new.listingId,
    }
    SendEventsFBEvent({
      type: "AddToCart",
      params,
    })
    SendEvents({ eventName: "booking/BookingStepSubmit/success", data: params })
  }
}

export const EmailSignupCompleteAndRequestSent = ({
  dispatch,
  getState,
}) => next => action => {
  next(action)
  if (
    action.type === "booking/BookingCreateNew/fulfilled" &&
    (action.meta.arg.bookingType === "SIGNUP_WITH_EMAIL" ||
      action.meta.arg.bookingType === "SIGNUP_WITH_OAUTH")
  ) {
    const { booking } = getState()
    const params = {
      // value: PremiumCheck(booking.new.departurePrimary)
      //   ? booking.new.pricePremium
      //   : booking.new.price,
      // currency: booking.new.currency,
      value: 65.0,
      currency: "CAD",
      // content_type: "product",
      // content_ids: booking.new.listingId,
    }
    SendEventsFBEvent({
      type: "AddToCart",
      params,
    })
    SendEvents({ eventName: "EmailSignupCompleteAndRequestSent", data: params })

    if (action.meta.arg.bookingType === "SIGNUP_WITH_OAUTH") {
      //Send event
      SendEvents({
        eventName: "booking/BookingStepSubmit/success",
        data: params,
      })
    }
  }
}

export const AnaltyicsMdl = [
  EmailSignupCompleteAndRequestSent,
  AnalyticsBookingRequestSentAddToCart,
  AnalyticsBookingSignupMdl,
  AnalyticsBookingPaymentSuccessMdl,
  AnalyticsPartnerWithUsSubmit,
]

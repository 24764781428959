import {
  makeUnauthUser,
  getUserData,
  UserIdentityCheck,
  UserLoginBefore,
  resetStatus,
  loginUser,
} from "../slices/userSlice"
import { BookingReset } from "../slices/bookingSlice"
import { setToast } from "../slices/toastSlice"
import { navigate } from "gatsby"
export const UserAuth = ({ dispatch, getState }) => next => action => {
  next(action)
  //user auth rejected so make a new temp user
  if (action.type === "user/getCurrentAuthUser/fulfilled") {
    if (action.payload?.username) {
      dispatch(
        getUserData({
          id: action.payload?.username,
          source: "UserMdl-UserAuth",
        })
      )
    } else {
      // throw Error("")
    }
  }
  if (action.type === "user/getCurrentAuthUser/rejected") {
    dispatch(makeUnauthUser())
  }
}

export const UserBankInfo = ({ dispatch, getState }) => next => action => {
  next(action)
  //user auth rejected so make a new temp user
  // if (action.meta?.arg?.payoutAccount) {
  //   if (action.type === 'user/UserUpdate/fulfilled') {
  //     dispatch(setToast({message: 'User profile updated', type: 'SUCCESS'}));
  //   }
  //   if (action.type === 'user/UserUpdate/rejected') {
  //     dispatch(
  //       setToast({message: 'Oops! Something went wrong!', type: 'FAILED'}),
  //     );
  //   }
  // }
}

export const UserIdentityMdl = ({ dispatch, getState }) => next => action => {
  next(action)
  if (
    action.type === "user/newUserPhotoThunk/fulfilled" &&
    (action.meta.arg.photoType === "GOVID" ||
      action.meta.arg.photoType === "SELFIE")
  ) {
    dispatch(setToast({ message: "Photo ID uploaded", type: "SUCCESS" }))
    dispatch(UserIdentityCheck())
  }

  if (
    action.type === "user/newUserPhotoThunk/rejected" &&
    (action.meta.arg.photoType === "GOVID" ||
      action.meta.arg.photoType === "SELFIE")
  ) {
    dispatch(
      setToast({ message: "Oops! Something went wrong!", type: "FAILED" })
    )
  }
}

export const UserPhotoMdl = ({ dispatch, getState }) => next => action => {
  next(action)
  if (
    action.type === "user/newUserPhotoThunk/fulfilled" &&
    action.meta.arg.photoType === "PROFILE"
  ) {
    dispatch(setToast({ message: "Profile photo uploaded", type: "SUCCESS" }))
  }

  if (
    action.type === "user/newUserPhotoThunk/rejected" &&
    action.meta.arg.photoType === "PROFILE"
  ) {
    dispatch(
      setToast({ message: "Oops! Something went wrong!", type: "FAILED" })
    )
  }
}

export const UserData = ({ dispatch, getState }) => next => action => {
  next(action)

  if (action.type === "user/loginUser/rejected") {
    const text = action?.payload?.code?.includes("Exception")
      ? action.payload.message
      : "Oops! Something went wrong!"
    dispatch(setToast({ message: text, type: "FAILED" }))
  }

  if (action.type === "user/UserUpdate/fulfilled") {
    if (
      !action.meta.arg.termsAccept &&
      !action.meta.arg.context !== "BOOKING"
    ) {
      dispatch(setToast({ message: "User profile updated", type: "SUCCESS" }))
    }
  }

  if (action.type === "user/UserUpdate/rejected") {
    dispatch(
      setToast({ message: "Oops! Something went wrong!", type: "FAILED" })
    )
  }

  if (action.type === "user/getUserData/fulfilled") {
    //user logged in but there is no user object in the database so redirect to login
    if (!action.payload.data?.getUserData) {
      if (action.meta.arg.source === "oauth") {
        //this is while the user on the booking-request page
        if (window.location.pathname.includes("create-new")) {
        } else {
          //this is while the user on the signup page
          window?.location?.replace(`/signup`)
        }
      }
    } else {
      dispatch(resetStatus())
      dispatch(UserIdentityCheck())
    }
  }

  if (action.type === "user/signOutUser/fulfilled") {
    dispatch(BookingReset())
    dispatch(makeUnauthUser())
    dispatch(UserLoginBefore())
    navigate("/")
  }

  if (action.type === "user/signOutUser/rejected") {
    dispatch(
      setToast({ message: "Oops! Something went wrong!", type: "FAILED" })
    )
  }
}

export const UserPassword = ({ dispatch, getState }) => next => action => {
  next(action)

  if (action.type === "user/forgotPassword/rejected") {
    const text = action?.error?.code?.includes("Exception")
      ? action.error.message
      : "Oops! Something went wrong!"
    dispatch(setToast({ message: text, type: "FAILED" }))
  }

  if (action.type === "user/forgotPasswordSubmit/fulfilled") {
    dispatch(
      setToast({ message: "Password updated please login", type: "SUCCESS" })
    )
    //login user here
    navigate("/login")
  }

  if (action.type === "user/changePassword/fulfilled") {
    dispatch(setToast({ message: "Password updated", type: "SUCCESS" }))
  }

  if (action.type === "user/forgotPasswordSubmit/rejected") {
    const text = action?.payload?.code?.includes("Exception")
      ? action.payload.message
      : "Oops! Something went wrong!"
    dispatch(setToast({ message: text, type: "FAILED" }))
  }

  if (action.type === "user/changePassword/rejected") {
    const text = action?.payload?.code?.includes("Exception")
      ? action.payload.message
      : "Oops! Something went wrong!"
    dispatch(setToast({ message: text, type: "FAILED" }))
  }
}

export const UserLogin = ({ dispatch, getState }) => next => action => {
  next(action)

  if (
    action.type === "user/loginUser/fulfilled" &&
    action.meta.arg.source === "LOGIN_PAGE_WITH_EMAIL"
  ) {
    const { user } = getState()
    //this is handling the user login from the /login form
    dispatch(getUserData({ id: user?.sub, source: "UserLoginMdl" }))
  }

  if (
    action.type === "user/loginUser/rejected" &&
    action?.payload?.code === "UserNotConfirmedException"
  ) {
    // dispatch(UserResendSignupCode({username: action.meta.arg.username}));
  }

  if (action.type === "user/verify/fulfilled") {
    // Was running twice, have same thing in Booking Mdl, delete later if not needed.
    // if (action.meta.arg.signupType === 'BOOKING') {
    //   const state = getState();
    //
    //   dispatch(
    //     loginUser({
    //       username: state.user.email,
    //       password: state.user.password,
    //       bookingType: 'WITH_SIGNUP',
    //     }),
    //   );
    // }

    if (action.meta.arg.signupType === "LOGIN") {
      const state = getState()
      dispatch(
        loginUser({
          username: state.user.email,
          password: state.user.password,
          source: "LOGIN",
        })
      )
    }

    if (action.meta.arg.signupType === "SIGNUP") {
      const state = getState()

      dispatch(
        loginUser({
          // username: action.meta.arg.username,
          // password: action.meta.arg.password,
          username: state.user.email,
          password: state.user.password,
          source: "SIGNUP",
        })
      )
    }
  }
}

export const UserMdl = [
  UserAuth,
  UserLogin,
  UserData,
  UserPassword,
  UserBankInfo,
  UserIdentityMdl,
  UserPhotoMdl,
]

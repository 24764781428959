import { BookingChatCreate, BookingHasNewMessages, BookingChatUpdate } from "../slices/bookingSlice"

export const ChatMessageNew = ({ dispatch }) => next => action => {
  next(action)
  if (action.type === "user/UserChatMessageCreate/fulfilled") {
    dispatch(
      BookingChatCreate({
        ...action.payload.data.createChat,
      })
    )
  }
}

export const ChatMessageUpdate = ({dispatch, getState}) => next => action => {
  //after toSeen has been posted, use the function to update chat item
  next(action);

  if (action.type === 'user/updateChat/fulfilled' && action.meta?.arg) {
    const {id, createdAt, toSeen} = action.meta.arg;
    if (id && createdAt && toSeen) {
      dispatch(
        BookingChatUpdate({
          id: action.meta.arg.id,
          createdAt: action.meta.arg.createdAt,
          toSeen: action.meta.arg.toSeen,
        }),
      );
    }
  }
  //This might need more work..
  if (action.type === 'booking/BookingChatUpdate') {
    const {user} = getState();
    dispatch(BookingHasNewMessages({id: user.data.id}));
  }
};


export const ChatMdl = [ChatMessageNew, ChatMessageUpdate]

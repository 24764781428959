import {
  ListingsNearBy,
  // ListingPhotoAutoSetPrimary,
  // ListingPhotoNewThunk,
  // ListingPhotoUpdateThunk,
  // ListingPhotosReplace,
} from "../slices/listingsSlice"
// import {
//   addNewUserListing,
//   UpdateUserListing,
//   // UserListingPhotosReplace,
// } from "../slices/userSlice"
// import { setToast } from "../slices/toastSlice"
// import { addNewUserListingPhoto } from "../slices/userSlice"
// import { LocationUpdate } from "../slices/locationSlice"
import { DID_NOT_MATCH } from "../slices/searchSlice"
const DISTANCE_FROM_LOCATION = 50
export const ListingsNearByMdl = ({ dispatch, getState }) => next => action => {
  next(action)

  function dispatchListingsNearBy(lat, lon) {
    const { user, listings } = getState()
    if (listings.status !== "loading") {
      dispatch(
        ListingsNearBy({
          variables: {
            limit: 100,
            location: {
              lat,
              lon,
            },
            km: DISTANCE_FROM_LOCATION,
          },
          authed: user.userUnauthed ? "UNAUTH" : "AUTH",
          init: "NEARBY",
        })
      )
    }
  }

  //user comes in for the first time and geo is fetched
  if (action.type === "location/LocationServiceFetch/fulfilled") {
    const {
      location: { lat, lng },
    } = action.payload
    dispatchListingsNearBy(lat, lng)
  }

  //fetch listings again after search that matches something to rehydrate
  if (action.type === "searching/SearchLocationInputSelect/fulfilled") {
    const { searching } = getState()
    if (!searching?.searchInput?.urlPath?.includes(DID_NOT_MATCH)) {
      const { lat, lng } = action.payload
      dispatchListingsNearBy(lat, lng)
    }
  }
}

// export const ListingUpdate = ({ dispatch, getState }) => next => action => {
//   next(action)
//   if (action.type === "listings/ListingUpdate/fulfilled") {
//     dispatch(setToast({ message: "Listing updated", type: "SUCCESS" }))
//     dispatch(UpdateUserListing(action.payload.data.updateListing))
//   }
//   if (action.type === "listings/ListingUpdate/rejected") {
//     dispatch(
//       setToast({ message: "Oops! Something went wrong!", type: "FAILED" })
//     )
//   }
// }

// export const ListingCreate = ({ dispatch, getState }) => next => action => {
//   next(action)
//
//   if (action.type === "listings/ListingCreate/fulfilled") {
//     dispatch(addNewUserListing(action.payload))
//     dispatch(
//       setToast({ message: "Your new listing was created", type: "SUCCESS" })
//     )
//   }
//   if (action.type === "listings/ListingCreate/rejected") {
//     dispatch(
//       setToast({ message: "Oops! Something went wrong!", type: "FAILED" })
//     )
//   }
// }

// export const ListingPhotoUpdate = ({
//   dispatch,
//   getState,
// }) => next => action => {
//   next(action)
//   if (action.type === "listings/ListingPhotoSetPrimary") {
//     // const { listings } = getState()
//     // listings.new.photos.map(item =>
//     //   dispatch(
//     //     ListingPhotoUpdateThunk({
//     //       id: item.id,
//     //       listingCover: action.payload.id === item.id,
//     //     })
//     //   )
//     // )
//   }
//
//   // if (action.type === "listings/ListingPhotoUpdateThunk/fulfilled") {
//   //   if (action.meta.arg?.listingCover !== false) {
//   //     dispatch(setToast({ message: "Photos changes saved", type: "SUCCESS" }))
//   //   }
//   //   const { listings } = getState()
//   //   const { id, photos } = listings.new
//   //   // dispatch(ListingPhotosReplace({ id, photos }))
//   //   // dispatch(UserListingPhotosReplace({ id, photos }))
//   // }
//   // if (action.type === "listings/ListingPhotoUpdateThunk/rejected") {
//   //   dispatch(
//   //     setToast({
//   //       message: "Oops! Something went updating the photo",
//   //       type: "FAILED",
//   //     })
//   //   )
//   // }
// }

// export const ListingPhotoCreate = ({
//   dispatch,
//   getState,
// }) => next => action => {
//   next(action)
//   if (action.type === "listings/ListingPhotoAdd") {
//     dispatch(ListingPhotoAutoSetPrimary())
//     const { user } = getState()
//     const item = action.payload
//
//     // Object.values(action.payload).map(item => {
//     if (item.path || item.sourceURL) {
//       dispatch(
//         ListingPhotoNewThunk({
//           id: item.id,
//           uri: item.path,
//           //uri: Platform.OS === 'android' ? item.path : item.sourceURL,
//           type: item.mime,
//           listingId: item.listingId,
//           listingCover: item.listingCover,
//           userId: user.data.id,
//           enabled: item.enabled,
//         })
//       )
//     }
//     // });
//   }
//   if (action.type === "listings/ListingPhotoNewThunk/fulfilled") {
//     dispatch(addNewUserListingPhoto({ ...action.payload }))
//     dispatch(ListingPhotoAutoSetPrimary())
//     dispatch(setToast({ message: "New photo added", type: "SUCCESS" }))
//   }
//   if (action.type === "listings/ListingPhotoNewThunk/rejected") {
//     dispatch(
//       setToast({
//         message: "Oops! Something went wrong uploading photo!",
//         type: "FAILED",
//       })
//     )
//   }
//   if (action.type === "listings/ListingPhotoDisable") {
//     dispatch(ListingPhotoAutoSetPrimary())
//   }
// }

export const ListingsMdl = [
  ListingsNearByMdl,
  // ListingCreate,
  // ListingUpdate,
  // ListingPhotoCreate,
  // ListingPhotoUpdate,
]

import { SearchApplyFilter, SearchStatusUpdate } from "../slices/searchSlice"

export const SearchFilterChange = ({
  dispatch,
  getState,
}) => next => action => {
  next(action)
  const { listings } = getState()
  if (action.type === "Search/SearchFilterSet") {
    dispatch(SearchApplyFilter({ items: listings.items }))
  }
}
export const SearchLoadingMdl = ({ dispatch, getState }) => next => action => {
  next(action)
  const { listings } = getState()
  if (action.meta?.arg?.init === "SEARCH") {
    if (action.type === "listings/ListingsNearBy/fulfilled") {
      dispatch(SearchApplyFilter({ items: listings.items }))
    }
    if (action.type === "listings/ListingsNearBy/rejected") {
      dispatch(SearchApplyFilter({ items: listings.items }))
    }
    if (action.type === "listings/ListingsNearBy/pending") {
      dispatch(SearchStatusUpdate({ status: "searching" }))
    }
  }
}

export const SearchMdl = [SearchFilterChange, SearchLoadingMdl]
